<template>
  <div class="t-hidden lg:t-flex t-h-screen t-w-full t-items-center t-justify-center t-flex-col">
    <p class="t-text-2xl">Widok dostępny tylko na urządzeniach mobilnych</p>
    <router-link to="/" class="t-mt-10">
      Wróć do logowania
    </router-link>
  </div>
  <div class="t-flex t-items-center t-flex-col t-justify-between t-h-screen t-w-screen t-py-10 t-pb-24 lg:t-pb-20 lg:t-pt-0 lg:t-hidden">
    <div class="flex-cen t-flex-col ">
      <h1 class="t-text-logo-font t-text-3xl">RoadReporter</h1>
      <p class="t-mt-3 t-text-sm t-text-info-font">Łatwiejsze raporty</p>
    </div>
    <p class="t-text-xl t-px-10">Konto utworzone pomyślnie, przejdź do logowania</p>
    <router-link to="/" class="flex-cen t-h-14 t-w-full t-bg-main-bgc-btn t-text-main-bgc-font t-text-xl t-rounded-xl t-border t-border-input-stroke t-shadow">
      Dalej
    </router-link>
  </div>
</template>

<script>

export default {

  setup(){


    return{
    }
  }
}
</script>

<style scoped>

</style>