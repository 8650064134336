<template>
  <div class="t-hidden lg:t-flex t-h-screen t-w-full t-items-center t-justify-center t-flex-col">
    <p class="t-text-2xl">Widok dostępny tylko na urządzeniach mobilnych</p>
    <router-link to="/" class="t-mt-10">
      Wróć do logowania
    </router-link>
  </div>
  <div class="t-py-10 lg:t-hidden t-overflow-hidden">
    <h1 class="t-text-logo-font t-text-2xl t-font-bold">Powrót do firmy</h1>
    <p class="t-mt-14 t-text-xl">Podaj wszystkie informację</p>
    <form class="t-flex t-flex-col t-px-4 t-w-full t-mt-8 t-text-start" @submit.prevent="addCustomPoint">
      <label class="label-style">Pojazd</label>
      <input readonly class="input-style t-h-14" v-model="selectedVehicleName" />
      <label class="t-mt-4 label-style">Przebieg pojazdu (aktualny - {{ currentMileageRef }} km)</label>
      <input
          v-model="mileage"
          type="number"
          class="input-style t-h-14"
          placeholder="km"
          required
      />
      <label class="t-mt-4 label-style">Typ punktu</label>
      <select v-model="customPointType" class="input-style t-h-12" required>
        <option value="">Wybierz typ punktu</option>
        <option v-for="(name, type) in pointTypeNames" :key="type" :value="type">{{ name }}</option>
      </select>

      <label class="t-mt-4 label-style">Nazwa punktu (np. Klient Pan Adam)</label>
      <input class="input-style t-h-14" v-model="customPointName"/>

      <label class="t-mt-4 label-style">Kraj</label>
      <input class="input-style t-h-14" v-model="customCountry"/>

      <label class="t-mt-4 label-style">Miasto</label>
      <input class="input-style t-h-14" v-model="customCity"/>

      <label class="t-mt-4 label-style">Ulica</label>
      <input class="input-style t-h-14" v-model="customStreet"/>

      <label class="t-mt-4 label-style">Kod pocztowy</label>
      <input class="input-style t-h-14" v-model="customPostCode"/>

      <button
          type="submit"
          class="t-mt-10 t-h-14 confirm-btn"
      >
        Zakończ podróż
      </button>
    </form>
  </div>
</template>


<script>
import {onMounted, ref} from "vue";
import {useStore} from 'vuex'
import TokenService from "@/plugins/token.service";
export default {
  setup(){
    const store = useStore();
    const mileage = ref("");
    const customPointType = ref("");
    const customPointName = ref("");
    const customCountry = ref("");
    const customCity = ref("");
    const customStreet = ref("");
    const customPostCode = ref("");
    const currentMileageRef = ref("");
    const selectedVehicleName = ref("");
    const refuel = ref("");

    const pointTypes = ref(["PETROL_STATION", "CUSTOMER", "CARPARK", "HIGHWAY_GATE"]);
    const pointTypeNames = {
      PETROL_STATION: "Stacja Paliw",
      CUSTOMER: "Klient",
      CARPARK: "Parking",
      HIGHWAY_GATE: "Brama Autostradowa"
    };


    onMounted(async () => {
      await refreshVehicle()
    });

    const refreshVehicle = async () => {
      await store.dispatch("getVehicles").then(res => {
        const all = res;
        const savedVehicleId = TokenService.getSelectedVehicleId();
        const vehicle = all.find(vehicle => vehicle.id.toString() === savedVehicleId);
        if (vehicle) {
          console.log(vehicle)
          selectedVehicleName.value = `${vehicle.vehicleName}, ${vehicle.regPlate}`;
          currentMileageRef.value = vehicle.currentMileage;
          console.log(currentMileageRef.value)
        }
      })
    }
    const addCustomPoint = () => {
      const formatDate = (date) => {
        const d = new Date(date),
            year = d.getFullYear(),
            month = `${d.getMonth() + 1}`.padStart(2, '0'),
            day = `${d.getDate()}`.padStart(2, '0'),
            hours = `${d.getHours()}`.padStart(2, '0'),
            minutes = `${d.getMinutes()}`.padStart(2, '0'),
            seconds = `${d.getSeconds()}`.padStart(2, '0');

        return [day, month, year].join('-') + ' ' + [hours, minutes, seconds].join(':');
      };
      const reportTime = formatDate(new Date());
      const customPoint = {
        companyId: TokenService.getCompany(),
        carId: Number(TokenService.getSelectedVehicleId()),
        reportTime: reportTime,
        reportTimeDateFormat: "dd-MM-yyyy HH:mm:ss",
        pointType : customPointType.value,
        pointName : customPointName.value,
        street : customStreet.value,
        postCode : customPostCode.value,
        city : customCity.value,
        country : customCountry.value,
        userId: TokenService.getUser().userId,
        currentMileage: parseFloat(mileage.value),
        refuel: Number(refuel.value),
      };
      console.log("custom punkt data to jkest", customPoint);
      store.dispatch("customPoint", customPoint)
          .then(() => {
            currentMileageRef.value = mileage.value;
            mileage.value = '';
            customCountry.value = '';
            customCity.value = '';
            customPostCode.value = '';
            customStreet.value = '';
            customPointName.value = '';
            customPointType.value = '';
            refuel.value = '';
          }, err => {
            console.log("Błąd podczas dodawania customowego punktu", err);
          });
    }
    return{
      mileage,
      currentMileageRef,
      addCustomPoint,
      selectedVehicleName,
      customPointType,
      customPointName,
      customCountry,
      customCity,
      customStreet,
      customPostCode,
      refuel,
      pointTypes,
      pointTypeNames,

    }
  }
}
</script>

<style scoped>

</style>