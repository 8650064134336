<template>
  <div class="t-py-6 t-w-8/12 t-h-screen t-mx-auto">
    <HeaderText class="t-hidden lg:t-flex"/>
    <div class="lg:t-hidden t-flex t-h-screen t-w-full t-items-center t-justify-center t-flex-col">
      <p class="t-text-2xl">Widok dostępny tylko na komputerach stacjonarnych i laptopach</p>
      <router-link to="/" class="t-mt-10">
        Wróć do logowania
      </router-link>
    </div>
    <PageHeader :pageTitle="pageTitle" :pageIcon="require('@/assets/history.png')" class="t-hidden lg:t-flex"/>
    <div class="t-flex t-flex-col t-mt-8 t-w-2/5 t-mx-auto">
      <label class="label-style t-text-start">Wybierz miesiąc i rok</label>
      <input type="month" class="input-style t-h-14" v-model="selectedMonthYear" @change="fetchHistoryReports"/>
    </div>
    <div class="t-flex t-flex-col t-mt-8 t-w-2/5 t-mx-auto">
      <label class="label-style t-text-start">Wyszukaj po nazwie pojazdu</label>
      <input placeholder="Wpisz nazwę pojazdu" class="input-style t-h-14" v-model="searchQuery"/>
    </div>
    <div class="t-flex t-items-center t-justify-center t-mt-8 t-w-full t-hidden lg:t-flex">

      <div class="t-w-full t-h-full t-max-h-[550px] t-h-max-[550px] t-overflow-y-scroll t-border t-border-input-stroke t-rounded-lg">
        <table class="t-w-full t-mx-auto t-bg-input-bgc t-shadow-md t-rounded-lg">
          <thead class="t-h-12">
          <tr class="t-border-b t-border-input-stroke">
            <th class="t-w-[200px]">Pojazd</th>
            <th class="t-w-[200px]">Miesiąc raportu</th>
            <th class="t-w-[200px]">Akcje</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(report, index) in filteredReports" :key="index" class="t-h-16 t-border-b t-border-dashed t-border-input-stroke">
            <td>{{ report.vehicleInfo.vehicleName }}</td>
            <td>{{ report.raportMonth }}</td>
            <td class="t-flex t-flex-col t-items-center">
              <button v-if="report.alreadySent" @click="downloadReport(report.id)" class="t-mt-4 t-flex t-flex-col t-items-center">
                <img src="@/assets/download.png" alt="download icon"/>
                <span class="t-text-xs">Pobierz</span>
              </button>
              <button v-else @click="sendReport(report.id)" class="t-mt-4 t-flex t-flex-col t-items-center">
                <img src="@/assets/mail.png" alt="download icon"/>
                <span class="t-text-xs">Wyślij na maila</span>
              </button>
            </td>
          </tr>
          </tbody>

        </table>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderText from "@/components/HeaderText";
import {computed, ref} from "vue";
import PageHeader from "@/components/PageHeader";
import {useStore} from "vuex";
import TokenService from "@/plugins/token.service";
export default {
  components: {PageHeader, HeaderText},
  setup() {
    const store = useStore();
    const pageTitle = ref("Historia raportów");
    const reports = computed(() => store.getters.historyReports);
    console.log('Reports:', reports.value);
    const searchQuery = ref('');
    const selectedMonthYear = ref('');

    const filteredReports = computed(() => {
      return reports.value.filter(report =>
          report.vehicleInfo.vehicleName.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });
    const sendReport = (raportId) => {
      const generateForm = {
        raportId: raportId
      };
      store.dispatch('sendReports', generateForm);
      console.log("wyslano raport")
    };

    const fetchHistoryReports = () => {
      if (selectedMonthYear.value) {
        const [year, month] = selectedMonthYear.value.split("-");
        const formattedDate = `${month.padStart(2, '0')}.${year}`;
        const paginationParams = {
          page: 0,
          size: 10,
          sort: ["vehicleName"]
        };
        store.dispatch('fetchHistoryWithDate', {
          companyId: TokenService.getCompany(),
          date: formattedDate,
          paginationParams
        });
      }
    };

    const downloadReport = (raportId) => {
      store.dispatch('downloadRaport', raportId);
    };
    return {
      pageTitle,
      reports,
      downloadReport,
      fetchHistoryReports,
      searchQuery,
      selectedMonthYear,
      filteredReports,
      sendReport
    }
  }
}
</script>

<style scoped>

</style>