import http from './general';

const AddressService = {
    editAddress(addressId, addressData) {
        return http.post(`/api/v1/addresses/edit/${addressId}`, addressData);
    },

    addAddress(addressData) {
        return http.post('/api/v1/addresses/add', addressData)
    },

    getAllAddresses(companyId) {
        return http.get(`/api/v1/addresses/all/${companyId}`);
    },

    removeAddress(addressId) {
        return http.delete(`/api/v1/addresses/remove/${addressId}`);
    }
}

export default AddressService;