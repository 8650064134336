<template>
  <div class="t-hidden lg:t-flex t-h-full t-w-full t-items-center t-justify-center t-flex-col">
    <p class="t-text-2xl">Widok dostępny tylko na urządzeniach mobilnych</p>
    <router-link to="/" class="t-mt-10">
      Wróć do logowania
    </router-link>
  </div>
  <div class="t-h-full t-py-10 lg:t-hidden t-overflow-hidden">
    <h1 class="t-text-logo-font t-text-2xl t-font-bold">Tankowanie</h1>
    <p class="t-mt-14 t-text-xl">Podaj wszystkie informację</p>
    <button class="t-hidden t-mt-8 t-font-bold t-bg-bgc-btn-option t-px-10 t-py-2 t-rounded-lg" @click="fetchGPS">Pobierz moją lokalizacje</button>
    <form class="t-flex t-flex-col t-px-4 t-w-full t-mt-8 t-text-start" @submit.prevent="addFuel">
      <label class="t-mt-4 label-style">Pojazd</label>
      <input readonly class="input-style t-h-14" v-model="selectedVehicleName" />
      <label class="t-mt-4 label-style">Przebieg pojazdu (aktualny - {{ currentMileageRef }} km)</label>
      <input
          v-model="mileage"
          type="number"
          class="input-style t-h-14"
          placeholder="km"
          required
      />
      <label class="t-mt-4 label-style">Ilość zatankowanego paliwa (l)</label>
      <input
          v-model="refuel"
          type="number"
          class="input-style t-h-14"
          placeholder="l"
          required
      />
      <button
          type="submit"
          class="t-mt-10 t-h-14 confirm-btn"
      >
        Dodaj tankowanie
      </button>
    </form>
  </div>
</template>

<script>
import {computed, onMounted, ref} from "vue";
import TokenService from "@/plugins/token.service";
import {useStore} from 'vuex'
export default {
  setup(){
    const store = useStore();
    const mileage = ref("");
    const fuelAmount = ref("");
    const averageConsumption = ref("");
    const gasStationName = ref("");
    const selectedVehicleId = ref("");
    const selectedVehicleName = ref("");
    const refuel = ref("");
    const currentMileageRef = ref("");
    const cars = computed(() => store.getters.vehicles);

    const city = ref("");
    const street = ref("");
    const postCode = ref("");
    const country = ref("");

    onMounted(async () => {
      await refreshVehicle()
    });

    const refreshVehicle = async () => {
      await store.dispatch("getVehicles").then(res=>{
        const all = res;
        const savedVehicleId = TokenService.getSelectedVehicleId();
        const vehicle = all.find(vehicle => vehicle.id.toString() === savedVehicleId);
        if (vehicle) {
          selectedVehicleName.value = `${vehicle.vehicleName}, ${vehicle.regPlate}`;
          currentMileageRef.value = vehicle.currentMileage;
        }
      })
    }

    const fetchGPS = ()=> {
      if (!("geolocation" in navigator)) {
        return;
      }
      navigator.geolocation.getCurrentPosition(async (position) => {
        const gpsLatitude = position.coords.latitude.toString();
        const gpsLongitude = position.coords.longitude.toString();
        await store.dispatch('fetchPoint',{gpsLatitude,gpsLongitude}).then(res =>{
          console.log("fetch gps",res)
          city.value = res.data.city || 'Brak danych';
          country.value = res.data.country || 'Brak danych';
          postCode.value = res.data.postCode;
          street.value = res.data.street;
        }), err =>{
          console.log("bład w pobieraniu adresuy", err);
        }
      })
    }

    const addFuel = () => {
      if (!("geolocation" in navigator)) {
        return;
      }
      navigator.geolocation.getCurrentPosition(async (position) => {
        const gpsLatitude = position.coords.latitude.toString();
        const gpsLongitude = position.coords.longitude.toString();

        const formatDate = (date) => {
          const d = new Date(date),
              year = d.getFullYear(),
              month = `${d.getMonth() + 1}`.padStart(2, '0'),
              day = `${d.getDate()}`.padStart(2, '0'),
              hours = `${d.getHours()}`.padStart(2, '0'),
              minutes = `${d.getMinutes()}`.padStart(2, '0'),
              seconds = `${d.getSeconds()}`.padStart(2, '0');

          return [day, month, year].join('-') + ' ' + [hours, minutes, seconds].join(':');
        };

        const reportTime = formatDate(new Date());
        const pointForm = {
          companyId: TokenService.getCompany(),
          carId: Number(TokenService.getSelectedVehicleId()),
          reportTime: reportTime,
          reportTimeDateFormat: "dd-MM-yyyy HH:mm:ss",
          pointType: "PETROL_STATION",
          pointName: 'Stacja paliw',
          refuel: Number(refuel.value),
          gpsLatitude, gpsLongitude,
          userId: TokenService.getUser().userId,
          currentMileage: parseFloat(mileage.value),
        };

        store.dispatch("addPoint", pointForm)
            .then(async () => {
              currentMileageRef.value =  mileage.value
              console.log("Punkt został dodany.");
              gasStationName.value = '';
              mileage.value = '';
              fuelAmount.value = '';
              averageConsumption.value = '';
              refuel.value = '';
              city.value = '';
              street.value = '';
              country.value = '';
              postCode.value = '';
            },error => {
              console.error("Bład podczas dodawania punktu:", error);
            });
      }, error => {
        console.error("Błąd geolokalizacji:", error);
      },);
    };
    return {
      mileage,
      addFuel,
      fuelAmount,
      averageConsumption,
      gasStationName,
      refuel,
      selectedVehicleId,
      selectedVehicleName,
      fetchGPS,
      currentMileageRef,
      cars,
      country,
      postCode,
      city,
      street,
    };
  }
}
</script>

<style scoped>
</style>

