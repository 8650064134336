<template>
  <div class="t-hidden lg:t-flex t-h-screen t-w-full t-items-center t-justify-center t-flex-col">
    <p class="t-text-2xl">Widok dostępny tylko na urządzeniach mobilnych</p>
    <router-link to="/" class="t-mt-10">
      Wróć do logowania
    </router-link>
  </div>
  <div class="t-py-10 lg:t-hidden t-overflow-hidden">
    <h1 class="t-text-logo-font t-text-2xl t-font-bold">Powrót do firmy</h1>
    <p class="t-mt-14 t-text-xl">Podaj wszystkie informację</p>
    <form class="t-flex t-flex-col t-px-4 t-w-full t-mt-8 t-text-start" @submit.prevent="returnCp">
      <label class="label-style">Pojazd</label>
      <input readonly class="input-style t-h-14" v-model="selectedVehicleName" />
      <label class="t-mt-4 label-style">Przebieg pojazdu (aktualny - {{ currentMileageRef }} km)</label>
      <input
          v-model="mileage"
          type="number"
          class="input-style t-h-14"
          placeholder="km"
          required
      />
      <button
          type="submit"
          class="t-mt-10 t-h-14 confirm-btn"
      >
        Zakończ podróż
      </button>
    </form>
  </div>
</template>


<script>
import {onMounted, ref} from "vue";
import {useStore} from 'vuex'
import TokenService from "@/plugins/token.service";
export default {
  setup(){
   const store = useStore();
    const mileage = ref("");
    const currentMileageRef = ref("");
    const selectedVehicleName = ref("");
    onMounted(async () => {
      await refreshVehicle()
    });
    const refreshVehicle = async () => {
      await store.dispatch("getVehicles").then(res => {
        const all = res;
        const savedVehicleId = TokenService.getSelectedVehicleId();
        const vehicle = all.find(vehicle => vehicle.id.toString() === savedVehicleId);
        if (vehicle) {
          console.log(vehicle)
          selectedVehicleName.value = `${vehicle.vehicleName}, ${vehicle.regPlate}`;
          currentMileageRef.value = vehicle.currentMileage;
          console.log(currentMileageRef.value)
        }
      })
    }
    const returnCp = () => {
      const formatDate = (date) => {
        const d = new Date(date),
            year = d.getFullYear(),
            month = `${d.getMonth() + 1}`.padStart(2, '0'),
            day = `${d.getDate()}`.padStart(2, '0'),
            hours = `${d.getHours()}`.padStart(2, '0'),
            minutes = `${d.getMinutes()}`.padStart(2, '0'),
            seconds = `${d.getSeconds()}`.padStart(2, '0');

        return [day, month, year].join('-') + ' ' + [hours, minutes, seconds].join(':');
      };
      const reportTime = formatDate(new Date());
      const returnData = {
        companyId: TokenService.getCompany(),
        carId: Number(TokenService.getSelectedVehicleId()),
        reportTime: reportTime,
        reportTimeDateFormat: "dd-MM-yyyy HH:mm:ss",
        userId: TokenService.getUser().userId,
        currentMileage: parseFloat(mileage.value),
      };
      console.log("Return data to jkest", returnData);
      store.dispatch("cpReturn", returnData)
          .then(async ()=>{
            currentMileageRef.value = mileage.value;
            mileage.value=''
          },err =>{
            console.log("Blad podczas dodawania powrotu do firmy", err)
          })
    }
    return{
      mileage,
      currentMileageRef,
      returnCp,
      selectedVehicleName

    }
  }
}
</script>

<style scoped>

</style>