<template>
  <div class="t-py-6 t-w-11/12 lg:t-w-8/12 t-h-full lg:t-h-screen t-mx-auto">
    <HeaderText class="t-hidden lg:t-flex"/>
    <PageHeader :pageTitle="pageTitle" :pageIcon="require('@/assets/map.png')" class="t-flex"/>
    <div class="t-flex t-mt-8 t-w-full">
      <div class="t-w-full t-h-full t-max-h-[550px] t-overflow-y-scroll t-border t-border-input-stroke t-rounded-lg t-hidden lg:t-flex">
        <table class="t-w-full t-mx-auto t-bg-input-bgc t-shadow-md t-rounded-lg ">
          <thead class="t-h-12">
          <tr class="t-border-b t-border-input-stroke">
            <th class="t-w-[200px]">Nazwa pojazdu</th>
            <th class="t-w-[200px]">Numer rejestracyjny</th>
            <th class="t-w-[200px]">Aktualny przebieg</th>
            <th class="t-w-[200px]">Minimalne spalanie (l/100km)</th>
            <th class="t-w-[200px]">Maksymalne spalanie (l/100km)</th>
            <th class="t-w-[50px]">Akcje</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(car, id) in cars" :key="car.id" class="t-h-16 t-border-b t-border-dashed t-border-input-stroke">
            <td>{{ car.vehicleName }}</td>
            <td>{{ car.regPlate }}</td>
            <td>{{ car.currentMileage }}</td>
            <td>{{ car.fuelConsumptionMin }}</td>
            <td>{{ car.fuelConsumptionMax }}</td>
            <td class="t-flex t-items-center t-justify-center t-h-16">
              <button @click="deleteCar(id)">
                <img src="@/assets/delete.png" alt="Delete icon"/>
              </button>
              <button @click="openEditDialog(car)" class="t-ml-2">
                <img src="@/assets/edit.png" alt="Edit icon" class="t-h-4 t-w-4"/>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="t-ml-8 t-p-4 t-w-[408px] t-h-[550px] t-bg-input-bgc t-border t-border-input-stroke t-rounded-lg t-shadow t-hidden lg:t-flex">
        <div class="t-flex t-justify-between t-items-start t-flex-col t-w-full t-h-full">
          <p class="t-font-bold">Dodaj nowy pojazd</p>
          <form
              class="t-flex t-flex-col t-justify-between t-w-full t-mt-4 t-h-full"
              @submit.prevent="addCar"
          >
            <div class="t-flex t-flex-col t-items-start t-w-full t-h-full">
              <label class="t-mb-1 label-style">Nazwa samochodu*</label>
              <input
                  v-model="vehicleName"
                  required
                  type="text"
                  class="input-style t-h-12"
              />
              <label class="t-mt-4 label-style">Numer rejestracyjny pojazdu*</label>
              <input
                  v-model="regPlate"
                  required
                  type="text"
                  class="input-style t-h-12"
              />
              <label class="t-mt-4 label-style">Minimalne spalanie (l/100km)*</label>
              <input
                  v-model="fuelConsumptionMin"
                  required
                  type="number"
                  class="input-style t-h-12"
              />
              <label class="t-mt-4 label-style">Maksymalne spalanie (l/100km)*</label>
              <input
                  v-model="fuelConsumptionMax"
                  required
                  type="number"
                  class="input-style t-h-12"
              />
              <label class="t-mt-4 label-style">Aktualny przebieg*</label>
              <input
                  v-model="currentMileage"
                  required
                  type="number"
                  class="input-style t-h-12"
              />
            </div>
            <div class="t-flex t-items-center t-justify-between t-w-full">
              <button class="flex-cen t-mt-4 t-h-14 t-w-full t-bg-btn-title t-text-main-bgc-font t-rounded-xl t-border t-border-input-stroke t-shadow t-text-lg">
                <span>Dodaj</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <!-- mobilka  -->
      <!-- mobilka - glówny panel -->
      <div class="t-flex t-flex-col t-w-full t-h-full lg:t-hidden">
        <button class="confirm-btn t-py-2" @click="isAddingPopup = true"> Dodaj nowy pojazd </button>
        <div class="t-mt-6">
          <div
              class="t-flex t-flex-col t-items-start t-w-full t-p-3 t-mb-8 t-h-full t-rounded-lg t-shadow t-border t-border-input-stroke"
              v-for="(car, index) in cars" :key="index"
          >
            <div class="t-flex t-justify-between t-items-center t-w-full">
              <p class="t-font-bold">{{ car.vehicleName }}</p>
              <p class="t-text-xs">#{{ car.id }}</p>
            </div>
            <p>{{ car.regPlate }}</p>
            <p>{{ car.currentMileage }}</p>
            <p>{{ car.fuelConsumption }}</p>
              <div class="t-flex t-justify-end t-items-center t-w-full">
                <button @click="deleteCar(id)">
                  <img src="@/assets/delete.png" alt="Delete icon"/>
                </button>
                <button @click="openEditDialog(car)" class="t-ml-2 t-px-2">
                  <img src="@/assets/edit.png" alt="Edit icon" class="t-h-4 t-w-4"/>
                </button>
              </div>
          </div>
        </div>
      </div>
      <!-- mobilka - dodawanie pojazdu  -->
      <div v-if="isAddingPopup" class="overlay"></div>
      <div v-if="isAddingPopup" class="edit-dialog t-bg-input-bgc t-border t-border-input-stroke t-rounded-lg t-shadow" >
        <form @submit.prevent="addCar" class="t-flex t-flex-col t-items-start t-w-full t-h-full">
          <label class="t-mb-1 label-style">Nazwa pojazdu*</label>
          <input v-model="vehicleName" type="text" class="input-style t-mb-2 lg:t-mb-0 t-h-10 lg:t-h-14" required/>

          <label class="t-mb-1 label-style">Tablica rejestracyjna*</label>
          <input v-model="regPlate" type="text" class="input-style t-mb-2 lg:t-mb-0 t-h-10 lg:t-h-14" required/>

          <label class="t-mb-1 label-style">Obecny przebieg pojazdu (km)*</label>
          <input v-model="currentMileage" type="text" class="input-style t-mb-2 lg:t-mb-0 t-h-10 lg:t-h-14" required/>

          <label class="t-mb-1 label-style">Minimalne spalanie (l/100km)*</label>
          <input v-model="fuelConsumptionMin" type="text" class="input-style t-mb-2 lg:t-mb-0 t-h-10 lg:t-h-14" required/>
          <label class="t-mb-1 label-style">Maksymalne spalanie (l/100km)*</label>
          <input v-model="fuelConsumptionMax" type="text" class="input-style t-mb-2 lg:t-mb-0 t-h-10 lg:t-h-14" required/>

          <div class="t-flex t-items-center t-justify-between t-w-full t-mt-4 t-px-1">
            <button @click="isAddingPopup = false">Anuluj</button>
            <button type="submit" class="flex-cen t-py-4 t-pl-4 t-text-font-color t-font-bold t-text-sm">Dodaj</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <NotificationInfo
      v-if="showAddNotification"
      :message="addNotificationMessage"
      @close="showAddNotification = false"
  />
  <NotificationInfo
      v-if="showDeleteNotification"
      :message="deleteNotificationMessage"
      @close="showDeleteNotification = false"
  />
  <div v-if="isEditDialogOpen" class="overlay">
  </div>
  <div v-if="isEditDialogOpen" class="edit-dialog t-bg-input-bgc t-border t-border-input-stroke t-rounded-lg t-shadow" >
    <form @submit.prevent="updateCar" class="t-flex t-flex-col t-items-start t-w-full t-h-full">
      <label class="t-mb-1 label-style">Nazwa pojazdu</label>
      <input v-model="editableCar.vehicleName" type="text" class="input-style t-h-14"/>

      <label class="t-mb-1 label-style">Numer rejestracyjny</label>
      <input v-model="editableCar.regPlate" type="text" class="input-style t-h-14"/>

      <label class="t-mb-1 label-style">Aktualny przebieg</label>
      <input v-model="editableCar.currentMileage" type="number" class="input-style t-h-14"/>

      <label class="t-mb-1 label-style">Minimalne spalanie (l/100km)</label>
      <input v-model="editableCar.fuelConsumptionMin" type="number" class="input-style t-h-14"/>

      <label class="t-mb-1 label-style">Maksymalne spalanie (l/100km)</label>
      <input v-model="editableCar.fuelConsumptionMax" type="number" class="input-style t-h-14"/>
      <div class="t-flex t-items-center t-justify-between t-w-full t-mt-4 t-px-1">
        <button @click="isEditDialogOpen = false">Anuluj</button>
        <button type="submit" class="flex-cen t-py-4 t-pl-4 t-text-font-color t-font-bold t-text-sm">Zapisz zmiany</button>
      </div>
    </form>
  </div>
</template>
<script>
import HeaderText from "@/components/HeaderText";
import {computed, onMounted, ref} from 'vue';
import {useStore} from 'vuex'
import NotificationInfo from "@/components/NotificationInfo";
import PageHeader from "@/components/PageHeader";
import TokenService from "@/plugins/token.service";
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
export default {
  components: {PageHeader, NotificationInfo, HeaderText},
  setup() {
    const store = useStore();
    const toast = useToast();

    const pageTitle = ref("Ustawienia pojazdów");
    const showAddNotification = ref(false);
    const addNotificationMessage = ref("");
    const showDeleteNotification = ref(false);
    const deleteNotificationMessage = ref("");

    const isEditDialogOpen = ref(false);
    const editableCar = ref({});

    const isAddingPopup = ref(false);

    const openEditDialog = (car) => {
      editableCar.value = {...car};
      isEditDialogOpen.value = true;
    };

    const cars = computed(() => store.getters.vehicles);

    onMounted(() => {
      store.dispatch('getVehicles');
    });

    const deleteCar = async (index) => {
      const vehicleId = cars.value[index].id;
      const companyId = TokenService.getCompany();
      await store.dispatch('deleteVehicle', {vehicleId, companyId}).then(() => {
        store.dispatch('getVehicles');
        toast.default("Pojazd został usunięty",{
          duration:2000,
          icon: false
        });
      });
    };

    const vehicleName = ref('');
    const regPlate = ref('');
    const currentMileage = ref('');
    const fuelConsumptionMax = ref('');
    const fuelConsumptionMin = ref('');
    const lastMileage = ref('');

    const addCar = async () => {
      const vehicleData = {
        fuelConsumptionMin: parseInt(fuelConsumptionMin.value),
        fuelConsumptionMax: parseInt(fuelConsumptionMax.value),
        regPlate: regPlate.value,
        currentMileage: parseInt(currentMileage.value),
        vehicleName: vehicleName.value,
        companyId: TokenService.getCompany()
      };
      await store.dispatch('addVehicle', vehicleData).then(() => {
        store.dispatch('getVehicles');
        toast.default("Pojazd został dodany pomyślnie",{
          duration:2000,
          icon: false
        });
        vehicleName.value = '';
        regPlate.value = '';
        currentMileage.value = '';
        fuelConsumptionMax.value = '';
        fuelConsumptionMin.value = '';
      }, error => {
        if (error.response && error.response.data && error.response.data.message) {
          toast.error("Błąd podczas aktualizacji adresu: " + error.response && error.response.data && error.response.data.message);
        }

      });
    };

    const updateCar = async () => {
      const companyId = TokenService.getCompany();
      const vehicleData = {
        ...editableCar.value,
        companyId
      };
      await store.dispatch('editVehicle', vehicleData).then(() => {
        store.dispatch('getVehicles');
        isEditDialogOpen.value = false;
        toast.default("Pojazd został zaktualizowany.",{
          duration:2000,
          icon: false
        });
      }, error => {
        toast.error("Nie udało się zaktualizować pojazdu. " + error.response && error.response.data && error.response.data.message);
      });
    };



    return {
      fuelConsumptionMin,
      fuelConsumptionMax,
      currentMileage,
      regPlate,
      vehicleName,
      cars,
      deleteCar,
      addCar,
      showAddNotification,
      addNotificationMessage,
      showDeleteNotification,
      pageTitle,
      deleteNotificationMessage,
      editableCar,
      isEditDialogOpen,
      openEditDialog,
      lastMileage,
      updateCar,
      isAddingPopup
    };
  },
}
</script>

<style scoped>
.edit-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  padding: 20px;
  width: 90%;
  max-width: 350px;
}

.edit-dialog form {
  display: flex;
  flex-direction: column;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
</style>