import http from "@/plugins/general"
import TokenService from "../plugins/token.service";
import AuthService from "@/plugins/auth.service";



const setup = () => {
    http.interceptors.request.use((config) => {

        const token = TokenService.getLocalAccessToken();
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    http.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            console.log("SETUPERR",err)
            const originalConfig = err.config;
            if (originalConfig.url !== "auth/login" && err.response) {
                if ((err.response.status === 401 || err.response.status === 500) && !originalConfig._retry) {
                    originalConfig._retry = true;
                    try {
                        const rs = await AuthService.refreshToken(
                            TokenService.getLocalRefreshToken());
                        const {refreshToken,accessToken} = rs.data;

                        TokenService.updateLocalAccessToken(accessToken)
                        TokenService.updateLocalRefreshToken(refreshToken)

                        return http(originalConfig);
                    } catch (_error) {
                        return Promise.reject(_error);
                    }
                }
            } else {
                console.log("setupAxios",err)
            }

            return Promise.reject(err);
        }
    );
}

export default setup;
