<template>
  <div class="t-flex t-items-start t-justify-start t-h-full t-p-4 t-w-[408px] t-bg-input-bgc t-border t-border-input-stroke t-rounded-lg t-shadow">
    <div class="t-flex t-items-start t-justify-start t-flex-col t-w-full t-h-full t-max-h-[500px] t-overflow-y-scroll t-px-2">
      <div class="t-flex t-justify-between t-w-full">
        <p class="t-font-bold">Pracownicy</p>
        <button
            class="t-w-6 t-h-6 t-bg-input-stroke t-rounded-sm t-text-logo-font t-font-bold"
            @click="toggleAddEmploPanel "
            v-if="addEmploPanel === false"
        >
          +
        </button>
      </div>
      <div class="t-flex t-flex-col t-w-full t-mt-4 t-h-full" v-if="addEmploPanel === false">
        <div
            v-for="(user, index) in companyUsers"
            :key="index"
            class="t-flex t-justify-between t-items-center t-my-2 t-pb-2 t-border-b t-border-dashed t-border-input-stroke"
        >
          <p class="t-text-xl">{{user.fullName }}</p>
          <button class="t-mr-1" @click="removeEmployee(index)">
            <img src="@/assets/delete.png" alt="Delete Icon"/>
          </button>
        </div>
      </div>
      <form class="t-flex t-flex-col t-justify-between t-h-full t-w-full t-mt-4 t-pb-28" @submit.prevent="inviteNewUser" v-if="addEmploPanel">
        <div class="t-flex t-flex-col t-items-start t-w-full t-h-full">
          <label class="t-mb-1 label-style">Imię</label>
          <input
              type="text"
              class="input-style t-h-14"
              v-model="firstName"
              required
          />
          <label class="t-mt-4 label-style">Nazwisko</label>
          <input
              type="text"
              class="input-style t-h-14"
              v-model="lastName"
              required
          />
          <label class="t-mt-4 label-style">E-mail</label>
          <input
              type="email"
              class="input-style t-h-14"
              v-model="email"
              required
          />
        </div>
        <div class="t-flex t-items-center t-justify-between t-w-full">
          <button
              class="flex-cen t-mt-4 t-h-14 t-w-full t-bg-btn-info t-text-main-bgc-font t-rounded-xl t-border t-border-input-stroke t-shadow t-text-lg"
              @click="addEmploPanel = false"
          >
            <span>Wróć</span>
          </button>
          <button class="flex-cen t-mt-4 t-h-14 t-w-full t-bg-btn-title t-text-main-bgc-font t-rounded-xl t-border t-border-input-stroke t-shadow t-text-lg" type="submit">
            <span>Dodaj</span>
          </button>
        </div>
      </form>
    </div>
    <NotificationInfo
        :message="notificationMessage"
        v-if="showNotification"
        @close="closeNotification"
    />
    <ErrorPopup :isVisible="showError" :message="errorMessage" @close="showError = false" />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { computed } from 'vue';
import { useStore } from 'vuex';
import NotificationInfo from "@/components/NotificationInfo.vue";
import TokenService from "@/plugins/token.service";
import ErrorPopup from "@/components/ErrorPopup";
export default {
  components: {
    NotificationInfo,
    ErrorPopup
  },
  setup() {
    const store = useStore();
    onMounted(() => {
      store.dispatch('getCompanyAllUsers');
    });
    const companyUsers = computed(() => store.getters.companyUsers);

    const addEmploPanel = ref(false);

    const showNotification = ref(false);
    const notificationMessage = ref("");
    const showError = ref(false);
    const errorMessage = ref('');

    const toggleAddEmploPanel  = () => {
      addEmploPanel.value = !addEmploPanel.value;
    };

    const firstName = ref('');
    const lastName = ref('');
    const email = ref('');

    const inviteNewUser = async () => {
      const inviteForm = {
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        companyId: TokenService.getCompany()
      };
      await store.dispatch('inviteUser', inviteForm)
          .then(async (response) => {
            console.log('Zapraszanie użytkownika:', response);
            await store.dispatch('getCompanyAllUsers');
            firstName.value = '';
            lastName.value = '';
            email.value = '';
            showNotification.value = true;
            notificationMessage.value = "Użytkownik został pomyślnie zaproszony.";

            showError.value = false;
            errorMessage.value = '';
          }, error => {
            showError.value = true;
            showNotification.value = false;
            if (error.response) {
              if (error.response.status === 500) {
                errorMessage.value = "Nie udało się dodać pracownika. Skontaktuj się z działem pomocy.";
              } else if (error.response.data && error.response.data.msg) {
                errorMessage.value = error.response.data.msg;
              } else {
                errorMessage.value = 'Nie udało się dodać pracownika. Spróbuj ponownie później.';
              }
            } else {
              errorMessage.value = 'Wystąpił błąd podczas dodawania pracownika. Spróbuj ponownie później.';
            }
          });
    };
    const removeEmployee = async (index) => {
      const employeeId = companyUsers.value[index].id;
      await store.dispatch('deleteUser', employeeId)
          .then(() => {
            showNotification.value = true;
            notificationMessage.value = `Pracownik został usunięty.`;
            store.dispatch('getCompanyAllUsers');
          },error => {
            console.error("Błąd podczas usuwania pracownika:", error);
            showNotification.value = true;
            notificationMessage.value = "Nie udało się usunąć pracownika. Spróbuj ponownie później.";
          });
    };

    const closeNotification = () => {
      showNotification.value = false;
      notificationMessage.value = "";
    };

    return {
      addEmploPanel,
      companyUsers,
      toggleAddEmploPanel ,
      email,
      lastName,
      firstName,
      removeEmployee,
      inviteNewUser,
      showNotification,
      notificationMessage,
      closeNotification,
      showError,
      errorMessage
    };
  },
};
</script>

<style scoped>
</style>
