import PointService from '@/plugins/point.service'
import TokenService from "@/plugins/token.service";
const state = {
    points: [],
};

const mutations = {
    SET_POINTS(state, points) {
        state.points = points;
    },
    ADD_POINT(state, point) {
        state.points.push(point);
    },
    REMOVE_POINT(state, pointId) {
        state.points = state.points.filter(point => point.id !== pointId);
    },
    UPDATE_POINT(state, updatedPoint) {
        const index = state.points.findIndex(p => p.id === updatedPoint.id);
        if (index !== -1) {
            state.points.splice(index, 1, updatedPoint);
        }
    },
};

const actions = {
    async getPoints({ commit }) {
        const companyId = TokenService.getCompany();
        PointService.getAllPoints(companyId).then(response => {
            commit('SET_POINTS', response.data);
        }, error => {
            console.error("Error get punktu:", error);
        });
    },
    deletePoint({ commit }, pointId) {
        PointService.deletePoint(pointId)
            .then(() => {
                commit('REMOVE_POINT', pointId);
                console.log("Point successfully deleted");
            })
            .catch(error => {
                console.error("Error deleting point:", error);
                commit('SET_REPORT_ERROR', error.message || "Failed to delete point");
            });
    },
    async addPoint( _, pointForm) {
        PointService.pointAdd(pointForm).then(() => {
        }, error => {
            console.error("Error dodawanie punktu:", error);
        });
    },
    async cpReturn (_, returnData){
        PointService.companyReturn(returnData).then(()=>{
        }, err => {
            console.log("Error powrót do firmy",err)
        })
    },
    async customPoint (_, customPoint){
        PointService.customPoint(customPoint).then(()=>{
        }, err => {
            console.log("Error dodawnaia customowego punktu",err)
        })
    },
    async editPoint({ commit }, pointData) {
        try {
            await PointService.pointEdit(pointData);
            commit('UPDATE_POINT', pointData);
        } catch (error) {
            console.error("Error edycja punktu", error);
            throw error;
        }
    },
    async getPointFromCar({ commit }, { companyId, carId }) {
        PointService.getPointFromCar(companyId, carId).then(response => {
            commit('SET_POINTS', response.data);
        }, error => {
            console.error("Error get punktu z caru:", error);
        });
    },
    async getPointFromCarAndDate({ commit }, { companyId, carId, month, year }) {
        PointService.getPointFromCarAndDate(companyId, carId, month, year).then(response => {
            commit('SET_POINTS', response.data);
        }, error => {
            console.error("Error otrzymac punkt z caru i daty:", error);
        });
    },
    async fetchPoint(_, { gpsLatitude, gpsLongitude }) {
        return PointService.fetchPoint(gpsLatitude, gpsLongitude)
            .then(response => {
                return response;
            }, error => {
                console.error("Error fetching point:", error);
            });
    },

};

const getters = {
    points: state => state.points,
};

export default {
    state,
    mutations,
    actions,
    getters,
};
