<template>
  <SpinnerLoading v-if="spinner"/>
  <LoadingScreen/>
  <div class="flex-cen t-flex-col t-h-screen t-w-screen t-pb-40 lg:t-pb-20">
    <div class="flex-cen t-flex-col">
      <h1 class="t-text-logo-font t-text-3xl">RoadReporter</h1>
      <p class="t-mt-3 t-text-sm t-text-info-font">Łatwiejsze raporty</p>
    </div>
    <div class="flex-cen t-flex-col lg:t-w-[400px] t-w-full t-px-4">
      <p class="t-my-10 t-text-xl t-text-info-font">Podaj nowe hasło</p>
      <form class="t-flex t-items-start t-flex-col t-w-full" @submit.prevent="setNewPassword">
        <label class="t-mb-1 label-style">Hasło</label>
        <input
            v-model="newPassword"
            class="input-style t-mb-4 t-h-14"
            type="password"
            required
        />
        <label class="t-mb-1 label-style">Powtórz hasło</label>
        <input
            v-model="repeatNewPassword"
            class="input-style t-h-14"
            type="password"
            required
        />
        <button
            class="t-mt-10 t-h-14 confirm-btn"
            type="submit"
        >
          Ustaw nowe hasło
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import SpinnerLoading from "@/components/SpinnerLoading";
import LoadingScreen from "@/components/LoadingScreen";
import { ref } from "vue";
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'NewPassFromRemind',
  components: {
    LoadingScreen,
    SpinnerLoading
  },
  setup() {
    const spinner = ref(false);
    const newPassword = ref('');
    const repeatNewPassword = ref('');
    const passwordError = ref('');
    const confirmPasswordError = ref('');
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const token = route.query.token || route.params.token;

    if (!token) {
      console.error("Nie ma tokena w URL");
    } else {
      console.log(`Token: ${token}`);
    }

    const setNewPassword = () => {
      passwordError.value = '';
      confirmPasswordError.value = '';

      if (newPassword.value.length < 8) {
        passwordError.value = 'Hasło musi zawierać co najmniej 8 znaków.';
        return;
      }
      if (!/\d/.test(newPassword.value)) {
        passwordError.value = 'Hasło musi zawierać co najmniej jedną cyfrę.';
        return;
      }
      if (!/[!@#$%^&*(),.?":{}|<>]/.test(newPassword.value)) {
        passwordError.value = 'Hasło musi zawierać co najmniej jeden znak specjalny.';
        return;
      }
      if (newPassword.value !== repeatNewPassword.value) {
        confirmPasswordError.value = 'Hasła nie są identyczne.';
        return;
      }

      spinner.value = true;
      store.dispatch('resetPass', {
        token: token,
        newPassword: newPassword.value
      }).then(() => {
        alert('Hasło zostało zresetowane.');
        router.push('/');
      }).catch(error => {
        console.error('Błąd resetowania hasła:', error);
        alert('Wystąpił błąd: ' + error.message);
      }).finally(() => {
        spinner.value = false;
      });
    };

    return {
      spinner,
      newPassword,
      repeatNewPassword,
      setNewPassword,
      passwordError,
      confirmPasswordError,
    }
  }
}
</script>

<style>
.error-message {
  color: red;
  margin-top: 7px;
  margin-left: 4px;
  font-size: 10px;
}
</style>
