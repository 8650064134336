import http from './general';

class CompanyService {
    updateCompany(companyData) {
        return http.post(`/api/v1/company/update`, companyData);
    }
    companyInfo(companyId){
        return http.get(`/api/v1/company/info/${companyId}`)
    }
    companyAllUsers(companyId){
        return http.get(`/api/v1/company/allusers/${companyId}`)
    }

}
export default new CompanyService();