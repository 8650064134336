import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AdminDashboard from "@/views/admin/AdminDashboard";
import RemindPass from "@/views/RemindPass";
import NewPassFromRemind from "@/views/NewPassFromRemind";
import ConfirmPassword from "@/views/ConfirmPassword";
import AddressSettings from "@/views/admin/AddressSettings";
import CarSettings from "@/views/admin/CarSettings";
import CompanySettings from "@/views/admin/CompanySettings";
import ReportsView from "@/views/admin/ReportsView";
import PasswordConfirmation from "@/views/driver/PasswordConfirmation";
import AccountConfirmInfo from "@/views/driver/AccountConfirmInfo";
import DriverDashboard from "@/views/driver/DriverDashboard";
import DriverRoad from "@/views/driver/driverViews/DriverRoad";
import DriverParking from "@/views/driver/driverViews/DriverParking";
import DriverCustom from "@/views/driver/driverViews/DriverCustom";
import DriverClient from "@/views/driver/driverViews/DriverClient";
import DriverGasStation from "@/views/driver/driverViews/DriverGasStation";
import DriverCompany from "@/views/driver/driverViews/DriverCompany";
import HistoryReports from "@/views/admin/HistoryReports";
import TestView from "@/views/test/TestView";
import DevTest from "@/views/test/DevTest";
import RoadGenerator from "@/views/test/RoadGenerator";
import RegisterView from "@/views/RegisterView";
import TokenService from "@/plugins/token.service";

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/remind-password',
        name: 'RemindPass',
        component: RemindPass
    },
    {
        path: '/new-password/:token?',
        name: 'NewPassFromRemind',
        component: NewPassFromRemind,
    },
    {
        path: '/confirm-password/:token?',
        name: 'ConfirmPassword',
        component: ConfirmPassword
    },
    {
        path: '/register',
        name: 'RegisterView',
        component: RegisterView
    },
    {
        path: '/admin-panel',
        name: 'AdminDashboard',
        component: AdminDashboard
    },
    {
        path: '/address-settings',
        name: 'AddressSettings',
        component: AddressSettings
    },
    {
        path: '/car-settings',
        name: 'CarSettings',
        component: CarSettings
    },
    {
        path: '/company-settings',
        name: 'CompanySettings',
        component: CompanySettings
    },
    {
        path: '/reports',
        name: 'ReportsView',
        component: ReportsView
    },
    {
        path: '/history',
        name: 'HistoryReports',
        component: HistoryReports
    },
    {
        path: '/password-confirmation/:token?',
        name: 'PasswordConfirmation',
        component: PasswordConfirmation,
        props: true
    },
    //driver
    {
        path: '/account-confirm',
        name: 'AccountConfirmInfo',
        component: AccountConfirmInfo
    },
    {
        path: '/driver-dashboard',
        name: 'DriverDashboard',
        component: DriverDashboard,
    },
    {
        path: '/driver-dashboard/parking',
        name: 'DriverParking',
        component: DriverParking,
    },
    {
        path: '/driver-dashboard/custom',
        name: 'DriverCustom',
        component: DriverCustom,
    },
    {
        path: '/driver-dashboard/gas-station',
        name: 'DriverGasStation',
        component: DriverGasStation,
    },
    {
        path: '/driver-dashboard/company',
        name: 'DriverCompany',
        component: DriverCompany,
    },
    {
        path: '/driver-dashboard/autostrada',
        name: 'DriverAutostrada',
        component: DriverRoad,
    },
    {
        path: '/driver-dashboard/client',
        name: 'DriverClient',
        component: DriverClient,
    },

    //test
    {
        path: '/test',
        name: 'TestView',
        component: TestView,
    },
    {
        path: '/road-generator',
        name: 'RoadGenerator',
        component: RoadGenerator,
    },
    {
        path: '/dev',
        name: 'DevTest',
        component: DevTest,
    },


]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
console.log("ROUTER")
   if ( to.name !== 'CompanySettings' &&TokenService.getUser()!=null&& TokenService.isFirstLogin()) {
        next("company-settings");
    }else{
     next();
   }
});


export default router
