<template>
  <div class="t-hidden lg:t-flex t-h-screen t-w-full t-items-center t-justify-center t-flex-col">
    <p class="t-text-2xl">Widok dostępny tylko na urządzeniach mobilnych</p>
    <router-link to="/" class="t-mt-10">
      Wróć do logowania
    </router-link>
  </div>
  <div class="lg:t-hidden t-h-screen t-py-10 t-relative">
    <div class="t-flex t-justify-between t-items-center t-mx-4">
      <button class="t-uppercase t-font-bold t-w-6 t-h-6" @click="toggleAdminView">
        <img src="@/assets/setting.png" alt="Settings Icon" class="t-w-6 t-h-6"/>
      </button>
      <h1 class="t-text-logo-font t-text-3xl">RoadReporter</h1>
      <button @click="logout"  class="t-flex t-items-center t-justify-center t-w-10 t-h-10 t-z-30">
        <img src="@/assets/sign-out.png" alt="Exit Icon" class="t-w-6 t-h-6"/>
      </button>
    </div>
    <p class="t-my-14 t-text-2xl">Witaj, {{fullName}}</p>
    <div class="t-flex t-flex-col t-px-4">
      <label class="t-text-start t-mb-1 label-style">Pojazd</label>
      <select v-model="selectedVehicleId" @change="updateSelectedVehicle" class="input-style t-h-14" required>
        <option value="">Wybierz pojazd</option>
        <option v-for="car in cars" :key="car.id" :value="car.id">{{ car.vehicleName }}, {{ car.regPlate }}</option>
      </select>
    </div>
    <div v-if="selectedVehicleId && isAdminView" class="t-flex t-flex-col t-px-4">
      <router-link
          v-for="option in filteredOptions"
          :key="option.route"
          :to="option.route"
          class="t-flex t-items-center t-px-6 t-mt-4 t-w-full t-h-[83px] t-rounded-xl t-shadow t-bg-bgc-btn-option"
      >
        <div>
          <img :src="require(`@/assets/emplo/${option.icon}`)" :alt="option.label" />
        </div>
        <div class="t-flex t-flex-col t-items-start t-ml-4">
          <p class="t-font-bold t-text-xl">{{ option.label }}</p>
          <span class="t-text-sm">{{ option.description }}</span>
        </div>
      </router-link>
      <p v-if="selectedVehicleId && needRefuel" class="t-my-4 t-text-xl t-text-red-500">
        Samochód wymaga zatankowania. Uzupełnij paliwo, aby móc dodawać pozostałe punkty.
      </p>
    </div>

    <div v-if="!isAdminView" class="t-flex t-flex-col t-px-4">
      <RouterButtons/>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, ref} from "vue";
import {useStore} from 'vuex'
import TokenService from "@/plugins/token.service";
import {useRouter} from "vue-router";
import RouterButtons from "@/components/admin/RouterButtons";
export default {
  components: {RouterButtons},
  setup() {
    const store = useStore();
    const router = useRouter();
    const fullName = ref("");
    const isAdminView = ref(true);
    const toggleAdminView = () => {
      isAdminView.value = !isAdminView.value;
    };

    const selectedVehicleId = ref("");
    const cars = computed(() => store.getters.vehicles);
    const filteredOptions = computed(() => {
      if (!selectedVehicleId.value) return [];
      const vehicle = cars.value.find(car => car.id === selectedVehicleId.value);
      if (!vehicle || vehicle.needRefuel) {
        return options.value.filter(option => option.label === 'Tankuję');
      } else {
        return options.value;
      }
    });

    const needRefuel = computed(() => {
      const vehicle = cars.value.find(car => car.id === selectedVehicleId.value);
      return vehicle ? vehicle.needRefuel : false;
    });


    const options = ref([
      { label: "Tankuję", icon: "fuel.png", description: "Wyznacz trasę twojego przejazdu", route: "/driver-dashboard/gas-station" },
      { label: "Parkuję", icon: "parking.png", description: "Poinformuj gdzie zaparkowałeś", route: "/driver-dashboard/parking" },
      { label: "Autostrada", icon: "road.png", description: "Poinformuj jaką autostradą jedziesz", route: "/driver-dashboard/autostrada" },
      { label: "Klient", icon: "user.png", description: "Dodaj adres klienta do raportu", route: "/driver-dashboard/client" },
      { label: "Powrót do firmy", icon: "user.png", description: "Powiadom o zakończeniu podróży", route: "/driver-dashboard/company" },
      { label: "Własny punkt", icon: "customm.png", description: "Stwórz nowy punkt", route: "/driver-dashboard/custom" },
      { label: "Ustawienia firmy", icon: "setting.png", description: "Sprawdź lub zaktualizuj dane firmy", route: "company-settings" },
    ]);


    const updateSelectedVehicle = () => {
      store.commit('SET_SELECTED_VEHICLE_ID', selectedVehicleId.value);
    };

    const logout = () => {
      TokenService.removeUser();
      router.push({name: 'home'});
      console.log('logowanie')

    }
    onMounted(() => {
      fullName.value = TokenService.getFullName();
      if (!TokenService.hasUser()) {
        router.push({ name: 'home' });
      }
      const selectedVehicleI = TokenService.getSelectedVehicleId();
      if (selectedVehicleI) {
        selectedVehicleId.value = selectedVehicleI;
      }
      store.dispatch('getVehicles');
    });
    return {
      options,
      cars,
      isAdminView,
      toggleAdminView,
      filteredOptions,
      needRefuel,
      selectedVehicleId,
      updateSelectedVehicle,logout,fullName
    };
  },
};
</script>

<style scoped>
</style>
