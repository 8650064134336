import AddressService from '@/plugins/address.service';
import TokenService from '@/plugins/token.service';

const state = {
    addresses: [],
    error: null,
};

const mutations = {
    SET_ADDRESSES(state, addresses) {
        state.addresses = addresses;
    },
    ADD_ADDRESS(state, address) {
        state.addresses.push(address);
    },
    REMOVE_ADDRESS(state, addressId) {
        state.addresses = state.addresses.filter(address => address.id !== addressId);
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
};

const actions = {
    getAddresses({ commit }) {
        const companyId = TokenService.getCompany();
        return AddressService.getAllAddresses(companyId)
            .then(response => {
                commit('SET_ADDRESSES', response.data);
                return response.data;
            }, error => {
                commit('SET_ERROR', error.response.data.message);
                return error;
            });
    },
    addAddress({ commit }, addressData) {
        return AddressService.addAddress(addressData)
            .then(response => {
                commit('ADD_ADDRESS', response.data);
                return response.data;
            }).catch (error => {
                const errorMsg = error.response.data.msg || "Wystąpił nieznany błąd";
                console.log(errorMsg)
                commit('SET_ERROR', error.response.data.message);
                return error;
            });
    },
    editAddress({ commit }, { addressId, addressData }) {
        return AddressService.editAddress(addressId, addressData)
            .then(() => {
                console.log("Sukces edycji adresu");
                this.dispatch('getAddresses');
            }, error => {
                console.error("Blad przy edycji adresu:", error);
                commit('SET_ERROR', error.response.data.message);
                return error;
            });
    },
    removeAddress({ commit }, addressId) {
        return AddressService.removeAddress(addressId)
            .then(() => {
                this.dispatch('getAddresses');
            }, error => {
                commit('SET_ERROR', error.response.data.message);
                return error;
            });
    }
};

const getters = {
    addresses: state => state.addresses,
    error: state => state.error,
};

export default {
    state,
    mutations,
    actions,
    getters,
};
