<template>
  <div class="t-py-6 t-w-8/12 t-h-screen t-mx-auto ">
    <HeaderText class="t-hidden lg:t-flex"/>
    <div class="lg:t-hidden t-flex t-h-screen t-w-full t-items-center t-justify-center t-flex-col">
      <p class="t-text-2xl">Widok dostępny tylko na komputerach stacjonarnych i laptopach</p>
      <router-link to="/" class="t-mt-10">
        Wróć do logowania
      </router-link>
    </div>
    <div class="t-mt-20 t-flex t-justify-between t-w-full t-hidden lg:t-flex">
      <div class="t-w-1/2">
        <div class="t-flex t-flex-col t-items-start t-justify-center">
          <router-link
              v-for="(item, index) in menuItems"
              :key="index"
              :to="`/${item.route}`"
              class="t-my-6 t-flex t-items-center t-px-6 t-w-[405px] t-h-[83px] t-bg-bgc-btn-option t-rounded t-shadow hover:t-shadow-lg t-transition-all"
          >
            <div class="t-w-[33px]">
              <img :src="require(`@/assets/${item.icon}`)" alt="Icon" />
            </div>
            <div class="t-flex t-flex-col t-items-start t-ml-6">
              <p class="t-mb-2 t-text-xl t-text-logo-font">{{ item.title }}</p>
              <p class="t-text-xs t-text-font-color">{{ item.description }}</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderText from "@/components/HeaderText";
export default {
  components: {HeaderText,},
  setup(){
    const menuItems = [
      { icon: 'map.png', title: 'Fake trasa', description: 'Sprawdź generowanie tras', route: 'road-generator' },
    ];
    return{menuItems}
  }
}
</script>

<style scoped>
</style>