<template>
  <router-view/>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500;600;700&display=swap');
#app {
  font-family: 'Jura', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color:#E4E4E4;
}
html {
  background-color:#E4E4E4;
}
/* width */
::-webkit-scrollbar {
  width: 2.5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5A5A5A;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5A5A5A;
}
</style>
