<template>
  <SpinnerLoading v-if="spinner"/>
  <LoadingScreen/>
  <div class="flex-cen t-flex-col t-h-screen t-w-screen t-pb-40 lg:t-pb-20">
    <div class="flex-cen t-flex-col">
      <h1 class="t-text-logo-font t-text-3xl">RoadReporter</h1>
      <p class="t-mt-3 t-text-sm t-text-info-font">Łatwiejsze raporty</p>
    </div>
    <div class="flex-cen t-flex-col lg:t-w-[400px] t-w-full t-px-4">
      <p class="t-my-10 t-text-xl t-text-info-font">Zarejestruj się</p>
      <form class="t-flex t-items-start t-flex-col t-w-full" @submit.prevent="register">
        <label class="t-mb-1 label-style">Imię*</label>
        <input
            type="text"
            class="input-style t-h-12"
            v-model="firstName"
            required
        />
        <label class="t-mt-4 label-style">Nazwisko*</label>
        <input
            type="text"
            class="input-style t-h-12"
            v-model="lastName"
            required
        />
        <label class="t-mt-4 label-style">E-mail*</label>
        <input
            type="email"
            class="input-style t-h-12"
            v-model="email"
            required
        />
        <label class="t-mt-4 label-style">Hasło*</label>
        <input
            type="password"
            class="input-style t-h-12"
            v-model="password"
            @blur="touched.password = true"
            required
        />
        <ul v-if="touched.password " class="t-flex t-flex-col t-items-center t-justify-center t-w-full t-text-red-700 t-text-xs">
          <li v-if="!hasUpperCase">Hasło musi zawierać przynajmniej jedną wielką literę</li>
          <li v-if="!hasNumber" >Hasło musi zawierać przynajmniej jedną cyfrę</li>
          <li v-if="!hasSpecialChar">Hasło musi zawierać przynajmniej jeden znak specjalny</li>
          <li v-if="!hasMinLength">Hasło musi mieć przynajmniej 6 znaków</li>
        </ul>
        <button
            type="submit"
            class="t-mt-10 t-h-12 confirm-btn"
            :class="{'t-opacity-50': !formFilled, 't-cursor-not-allowed': !formFilled}"
        >
          Zarejestruj
        </button>
      </form>
      <div class="t-flex t-items-center t-justify-center t-flex-col t-mt-8 t-w-[400px] t-px-4 t-hidden lg:t-flex">
        <p class="t-text-xl t-text-info-font">Masz juz konto?</p>
        <router-link
            to="/"
            class="flex-cen t-mt-4 t-h-12 t-w-full t-bg-btn-info t-text-main-bgc-font t-text-xl t-rounded-xl t-border t-border-input-stroke t-shadow"
        >
          <span>Zaloguj się</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from 'vuex'
import {computed, ref} from 'vue';

import SpinnerLoading from "@/components/SpinnerLoading";
import LoadingScreen from "@/components/LoadingScreen";
import {useRouter} from "vue-router";
export default {
  name: 'RegisterView',
  components: {LoadingScreen, SpinnerLoading},
  setup() {
    const store = useStore();
    const router = useRouter();
    const spinner = ref(false);
    const firstName = ref('');
    const lastName = ref('');
    const email = ref('');
    const password = ref('');
    const touched = ref({
      password: false,
    });
    //walidacja hasła
    const hasUpperCase = computed(() => /[A-Z]/.test(password.value));
    const hasNumber = computed(() => /[0-9]/.test(password.value));
    const hasSpecialChar = computed(() => /[!@#$%^&*(),.?":{}|<>]/.test(password.value));
    const hasMinLength = computed(() => password.value.length >= 6);

    const formFilled = computed(() => {
      return firstName.value !== '' && lastName.value !== '' && email.value !== '' && password.value !== ''
    });

    const isPasswordValid = computed(() => {
      return hasUpperCase.value && hasNumber.value && hasSpecialChar.value && hasMinLength.value;
    });

    const validateEmail = (email) => {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    };

    const register = async () => {
      if(formFilled.value && isPasswordValid.value){

        console.log("start");
        spinner.value = true;
        const registerForm = {
          firstName: firstName.value,
          lastName: lastName.value,
          email: email.value,
          password: password.value
        };
        await store.dispatch('register', registerForm).then(res=>{
          spinner.value = false;
          console.log("odpowiedź z serwera:", res);
          if (res) {
            alert('Rejestracja przebiegła pomyślnie. Potwierdź swój adres e-mail.');
            firstName.value = '';
            lastName.value = '';
            email.value = '';
            password.value = '';
            const mobile = window.matchMedia('(max-width: 768px)').matches;
            if (!mobile) {
              router.push({name: 'AdminDashboard'});
            } else {
              router.push({name: 'DriverDashboard'});
            }
          }

        }, error => {
          spinner.value = false;
          console.error("Błąd:", error);
          let msg = 'Wystąpił błąd podczas rejestracji. Spróbuj ponownie później.';
          if (error.response && error.response.data && error.response.data.message) {
            msg = error.response.data.message;
            console.log("Szczegóły błędu:", msg);
          }
          alert(msg);
        });
      }
    };
    return {
      hasUpperCase,
      hasNumber,
      hasSpecialChar,
      hasMinLength,
      spinner,
      firstName,
      lastName,
      touched,
      email,
      validateEmail,
      isPasswordValid,
      password,
      formFilled,
      register
    }
  }
}
</script>
