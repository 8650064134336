<template>
  <div class="t-py-6 t-w-11/12 lg:t-w-8/12 t-h-screen t-mx-auto">
    <HeaderText class="t-hidden lg:t-flex"/>
    <PageHeader :pageTitle="pageTitle" :pageIcon="require('@/assets/setting.png')" class="t-flex"/>
    <div class="t-flex t-items-center t-justify-center t-mt-8 t-w-full">
      <div class="t-ml-0 lg:t-ml-8 t-p-4 t-w-full lg:t-w-[408px] t-h-full t-bg-input-bgc t-border t-border-input-stroke t-rounded-lg t-shadow">
        <div class="t-flex t-justify-between t-items-start t-flex-col t-w-full t-h-full">
          <p class="t-font-bold">Dane firmy</p>
          <form
              class="t-flex t-flex-col t-justify-between t-w-full t-mt-4 t-h-full"
              @submit.prevent="updateCompany"
          >
            <div class="t-flex t-flex-col t-items-start t-w-full t-h-full">
              <label class="t-mb-1 label-style">Nazwa firmy / Imię i Nazwisko*</label>
              <input
                  v-model="companyData.companyName"
                  required
                  type="text"
                  class="company-settings-input t-h-10"
              />
              <label class="t-mt-2 label-style">NIP*</label>
              <input
                  v-model="companyData.nip"
                  required
                  type="text"
                  class="company-settings-input t-h-10"
              />
              <label class="t-mt-2 label-style">Miejscowość*</label>
              <input
                  v-model="companyData.city"
                  type="text"
                  class="company-settings-input t-h-10"
              />
              <label class="t-mt-2 label-style">Ulica*</label>
              <input
                  v-model="companyData.street"
                  required
                  type="text"
                  class="company-settings-input t-h-10"
              />
              <label class="t-mt-2 label-style">Numer domu*</label>
              <input
                  v-model="companyData.homeNo"
                  required
                  type="text"
                  class="company-settings-input t-h-10"
              />
              <label class="t-mt-2 label-style">Numer lokalu*</label>
              <input
                  v-model="companyData.localNo"
                  type="text"
                  class="company-settings-input t-h-10"
              />
              <label class="t-mt-2 label-style">Kod pocztowy*</label>
              <input
                  v-model="companyData.postalCode"
                  required
                  type="text"
                  class="company-settings-input t-h-10"
              />
            </div>
            <div class="t-flex t-items-center t-justify-between t-w-full">
              <button type="submit" class="flex-cen t-mt-4 t-h-14 t-w-full t-bg-btn-title t-text-main-bgc-font t-rounded-xl t-border t-border-input-stroke t-shadow t-text-lg">
                <span>Aktualizuj</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderText from "@/components/HeaderText";
import PageHeader from "@/components/PageHeader";
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import TokenService from "@/plugins/token.service";
import {useRouter} from "vue-router";

export default {
  components: {PageHeader, HeaderText},
  setup() {
    const pageTitle = ref("Ustawienia firmy");
    const store = useStore();
    const router = useRouter();
    const companyData = ref({
      companyName: '',
      nip: '',
      city: '',
      street: '',
      homeNo:'',
      localNo:'',
      postalCode: '',
      companyId: TokenService.getCompany()
    });

    onMounted(() => {
      const companyId = TokenService.getCompany();
      store.dispatch("getCompanyInfo", companyId);
    });

    watch(() => store.getters.companyInfo, (newVal) => {
      companyData.value = { ...newVal };
    }, { deep: true });


    const updateCompany = async () => {
      const companyId = TokenService.getCompany();
      const companyObject = {
        ...companyData.value,
        companyId
      };
      await store.dispatch("updateCompanyInfo", companyObject).then(async () => {
        await store.dispatch("getCompanyInfo", companyId);
        const updatedInfo = store.getters.companyInfo;
        companyData.value = { ...updatedInfo };
        TokenService.setFirstLogin();
        await router.push({name: 'DriverDashboard'});
      },err => {
        console.log("error: ", err)
      })
    };

    return {
      companyData,
      pageTitle,
      updateCompany,
    };
  },
}
</script>

<style scoped>
</style>
