import ReportService from "@/plugins/report.service";
import TokenService from "@/plugins/token.service";

const state = {
    reports: [],
    reportError: null,
    currentRaport: [],
    historyReports: [],
};

const mutations = {
    SET_REPORTS(state, reports) {
        state.reports = reports;
    },
    SET_REPORT_ERROR(state, error) {
        state.reportError = error;
    },
    SET_CURRENT_RAPORT(state, raport) {
        state.currentRaport = raport;
    },
    SET_HISTORY_REPORTS(state, history) {
        state.historyReports = history;
    },

};

const actions = {
        fetchReports({ commit }, fetchData) {
            console.log('Wysyłanie danych do fetchReports:', fetchData);

            ReportService.fetchRaports(fetchData)
                .then(response => {
                    console.log('Odpowiedź z fetchReports:', response.data);
                    commit('SET_REPORTS', response.data);
                })
                .catch(error => {
                    console.error("Error fetching reports:", error);
                    commit('SET_REPORT_ERROR', error.message || "Failed to fetch reports");
                });
    },

    sendReports({ commit }, generateForm) {
        const data = {
            ...generateForm,
            companyId: TokenService.getCompany()
        };

        ReportService.sendReports(data)
            .then(() => {
                commit('SET_REPORT_ERROR', null);
            })
            .catch(error => {
                console.error("Error generating report:", error);
                commit('SET_REPORT_ERROR', error.message || "Failed to generate report");
            });
    },
    async getRaportById({ commit }, raportId) {
        try {
            const response = await ReportService.getRaports(raportId);
            commit('SET_CURRENT_RAPORT', response.data);
        } catch (error) {
            console.error("Error getting the raport:", error);
            commit('SET_REPORT_ERROR', error.message || "Failed to get the raport");
        }
    },
    downloadRaport({ commit }, raportId) {
        ReportService.downloadRaport(raportId)
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `raport_${raportId}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch(error => {
                console.error("Error downloading the report:", error);
                commit('SET_REPORT_ERROR', error.message || "Failed to download report");
            });
    },
    fetchHistoryWithDate({ commit }, { companyId, date }) {
        ReportService.getHistoryWithDate(companyId, date)
            .then(response => {
                commit('SET_HISTORY_REPORTS', response.data.content || []);
            })
            .catch(error => {
                console.error("Error fetching history:", error);
                commit('SET_REPORT_ERROR', error.message || "Failed to fetch history");
            });
    },

};

const getters = {
    reports: state => state.reports,
    reportError: state => state.reportError,
    historyReports: state => state.historyReports || [],};

export default {
    state,
    mutations,
    actions,
    getters,
};