<template>
  <div class="notification-container">
    <div class="notification">
      <p>{{ message }}</p>
      <button @click="closeNotification">Zamknij</button>
    </div>
  </div>
</template>

<script>
import {onMounted} from "vue";

export default {
  props: {
    message: String,
  },
  setup(props, { emit }) {
    const closeNotification = () => {
      emit("close");
    };
    onMounted(() => {
      setTimeout(() => {
        closeNotification();
      }, 2000);
    });
    return {
      closeNotification,
    };
  },
};
</script>

<style scoped>
.notification-container {
  position: fixed;
  bottom: 20px;
  right: 2%;
  z-index: 1000;
}

.notification {
  background-color: #181818;
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
}

.notification button {
  background-color: #fff;
  color: #181818;
  border: none;
  padding: 8px;
  border-radius: 3px;
  cursor: pointer;
}
</style>
