import CompanyService from '@/plugins/company.service';
import TokenService from '@/plugins/token.service';

const state = {
    companyInfo: {},
    companyUsers: [],
    error: null,
};

const mutations = {
    SET_COMPANY_INFO(state, info) {
        state.companyInfo = info;
    },
    SET_COMPANY_USERS(state, users) {
        state.companyUsers = users;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
};

const actions = {
    getCompanyInfo({ commit }) {
        const companyId = TokenService.getCompany();
        return CompanyService.companyInfo(companyId).then(response => {
            commit('SET_COMPANY_INFO', response.data);
            return response.data;
        }, error => {
            console.error("Error w getCompanyInfo:", error);
            const errorMessage = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : "Wystapil blad";
            commit('SET_ERROR', errorMessage);
            return error;
        });
    },

    getCompanyAllUsers({ commit }) {
        const companyId = TokenService.getCompany();
        return CompanyService.companyAllUsers(companyId).then(response => {
            commit('SET_COMPANY_USERS', response.data);
            return response.data;
        }, error => {
            console.error("Error w getCompanyAllUsers:", error);
            const errorMessage = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : "Wystąpił błąd.";
            commit('SET_ERROR', errorMessage);
            return error;
        });
    },

    updateCompanyInfo({ commit }, companyObject) {
        return CompanyService.updateCompany(companyObject).then(response => {
            commit('SET_COMPANY_INFO', response.data);
            return response.data;
        }, error => {
            console.error("Error in updateCompanyInfo:", error);
            commit('SET_ERROR', error.response.data.message);
            return error;
        });
    }

}
    const getters = {
        companyInfo: state => state.companyInfo,
        companyUsers: state => state.companyUsers,
        error: state => state.error,
    }

export default {
    state,
    mutations,
    getters,
    actions,
};
