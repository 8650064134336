<template>
  <div class="t-flex t-flex-col t-items-start t-justify-center">
    <router-link
        v-for="(item, index) in menuItems"
        :key="index"
        :to="`/${item.route}`"
        class="t-mt-4 lg:t-mt-0 lg:t-my-6 t-flex t-items-center t-px-6 t-w-full lg:t-w-[405px] t-h-[83px] t-bg-bgc-btn-option t-rounded-xl lg:t-rounded t-shadow hover:t-shadow-lg t-transition-all"
    >
      <div class="t-w-[33px]">
        <img :src="require(`@/assets/${item.icon}`)" alt="Icon" />
      </div>
      <div class="t-flex t-flex-col t-items-start t-ml-4 lg:t-ml-6">
        <p class="t-mb-2 t-text-xl t-text-logo-font t-font-bold lg:t-font-normal">{{ item.title }}</p>
        <p class="t-text-sm lg:t-text-xs t-text-font-color t-text-start">{{ item.description }}</p>
      </div>
    </router-link>
  </div>
</template>
<script>
export default {

  setup(){
    const menuItems = [
      { icon: 'map.png', title: 'Ustawienia adresów', description: 'Dodaj lub usuń adresy', route: 'address-settings' },
      { icon: 'car.png', title: 'Ustawienia pojazdów', description: 'Dodaj lub usuń samochody', route: 'car-settings' },
      { icon: 'setting.png', title: 'Ustawienia firmy', description: 'Sprawdź lub zaktualizuj dane firmy', route: 'company-settings' },
      { icon: 'file.png', title: 'Raporty', description: 'Miesięczne raporty pracowników', route: 'reports' },
      { icon: 'history.png', title: 'Historia', description: 'Zobacz historyczne raporty', route: 'history' },
    ];
    return{menuItems}
  }
}
</script>

<style scoped>

</style>