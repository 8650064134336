<template>
  <div class="t-py-6 t-w-8/12 t-h-screen t-mx-auto ">
   <HeaderText class="t-hidden lg:t-flex"/>
    <div class="lg:t-hidden t-flex t-h-screen t-w-full t-items-center t-justify-center t-flex-col">
      <p class="t-text-2xl">Widok dostępny tylko na komputerach stacjonarnych i laptopach</p>
      <router-link to="/" class="t-mt-10">
        Wróć do logowania
      </router-link>
    </div>
    <div class="t-mt-20 t-flex t-justify-between t-w-full t-hidden lg:t-flex">
      <div class="t-w-1/2">
        <RouterButtons/>
      </div>
      <div class="t-flex t-items-end t-justify-end t-w-1/2">
        <EmployeePanel/>
      </div>
    </div>
  </div>
</template>

<script>
import RouterButtons from "@/components/admin/RouterButtons";
import EmployeePanel from "@/components/admin/EmployeePanel";
import HeaderText from "@/components/HeaderText";
import {onMounted} from "vue";
export default {
  components: {HeaderText, EmployeePanel, RouterButtons}
  ,setup() {


    onMounted(() => {
      if (window.innerWidth < 1024) {
        window.location.href = "/";
      }
    });
  }
}
</script>

<style scoped>
</style>
