import { createStore } from 'vuex'

import auth from "./auth";
import vehicle from "./vehicle";
import company from "./company";
import address from "./address";
import point from "./point";
import reports from "@/store/reports";

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    vehicle,
    company,
    point,
    reports,
    address
  }
})
