import http from './general';

class VehicleService {
    vehicleEdit(vehicleData){
        return http.post('/api/v1/vehicle/edit', vehicleData);
    }
    vehicleAdd(vehicleData){
        console.log(vehicleData)
        return http.post('/api/v1/vehicle/add', vehicleData);
    }
    vehicleGetAll(companyId){
        return http.get(`/api/v1/vehicle/all/${companyId}`);
    }
    vehicleDelete(vehicleId){
        return http.delete(`/api/v1/vehicle/remove/${vehicleId}`);
    }
}

export default new VehicleService();