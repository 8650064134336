import AuthService from '@/plugins/auth.service';
import TokenService from '@/plugins/token.service';

const state = {
    user: TokenService.getUser(),
    authError: null,
};
const getters = {
    isAuthenticated: state => !!state.user,
    user: state => state.user,
    authError: state => state.authError,
};
const mutations = {
    SET_USER(state, user) {
        state.user = user;
        TokenService.setUser(user);
    },
    CLEAR_USER(state) {
        state.user = null;
        TokenService.removeUser();
    },
    SET_AUTH_ERROR(state, error) {
        state.authError = error;
    },
};

const actions = {
    register({commit}, registerForm) {
        return AuthService.registerCompany(registerForm).then(response => {
            commit('SET_USER', response.data);
            return response.data;
        }).catch (error => {
            const errorMsg = error.response && error.response.data && error.response.data.msg
                ? error.response.data.msg
                : "jakiś błąd.";
            console.log(errorMsg)
            commit('SET_AUTH_ERROR', errorMsg);
            return error;
        });
    },
    loginUser({ commit }, loginForm) {
        return AuthService.loginUser(loginForm)
            .then(response => {
                commit('SET_USER', response.data);
                return response;
            })
            .catch(error => {
                const errorMsg = error.response.data.msg || "Wystąpił nieznany błąd";
                console.log(errorMsg)
                commit('SET_AUTH_ERROR', errorMsg);
                return Promise.reject(error);
            });
    },
    inviteUser({commit}, inviteForm) {
        return AuthService.inviteUser(inviteForm).then(response => {
            return response.data;
        }). catch(error => {
            const errorMsg = error.response.data.msg || "Wystąpił nieznany błąd";
            console.log(errorMsg)
            commit('SET_AUTH_ERROR', error.response.data.message);
            return error;
        });
    },
    deleteUser({commit}, userId) {
        return AuthService.deleteUser(userId).then(
            response => {
                commit('SOME_MUTATION', response.data);
                return response.data;
            },
            error => {
                commit('SET_AUTH_ERROR', error.response.data.message);
                return (error);
            }
        );
    },
    confirmRegistration({ commit }, confirmForm) {
        return AuthService.confirmRegistration(confirmForm).then(
            response => {
                return response.data;
            }, error => {
                const errorMessage = error.response && error.response.data && error.response.data.message
                    ? error.response.data.message
                    : "Wystąpił błąd przy potwierdzaniu rejestracji.";
                commit('SET_AUTH_ERROR', errorMessage);
                return (error);
            }
        );
    },
    remindPass({ commit }, emailInfo) {
        return AuthService.remindPass(emailInfo)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                const errorMsg = error.response?.data?.msg || "Failed to send password reminder.";
                console.error(errorMsg);
                commit('SET_AUTH_ERROR', errorMsg);
                return Promise.reject(error);
            });
    },

    resetPass({ commit }, resetInfo) {
        return AuthService.resetPass(resetInfo)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                const errorMsg = error.response?.data?.msg || "Failed to reset password.";
                console.error(errorMsg);
                commit('SET_AUTH_ERROR', errorMsg);
                return Promise.reject(error);
            });
    },
}

export default {
    state,
    mutations,
    actions,
    getters
};
