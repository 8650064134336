import http from './general';
import TokenService from "@/plugins/token.service";

class PointService {
    pointEdit(pointData) {
        return http.post('/api/v1/point/edit', pointData);
    }
    pointAdd(pointForm){
        return http.post('/api/v1/point/add',{
            companyId: TokenService.getCompany(),
            carId: Number(TokenService.getSelectedVehicleId()),
            reportTime: pointForm.reportTime,
            reportTimeDateFormat: pointForm.reportTimeDateFormat,
            pointType: pointForm.pointType,
            pointName: pointForm.pointName,
            gpsLatitude: pointForm.gpsLatitude,
            gpsLongitude: pointForm.gpsLongitude,
            userId: TokenService.getUser().userId,
            currentMileage: pointForm.currentMileage,
            refuel: pointForm.refuel
        });
    }
    getPointFromCar(companyId,carId){
        return http.get(`/api/v1/point/get/${companyId}/${carId}`);
    }
    getPointFromCarAndDate(companyId,carId,month,year){
        return http.get(`/api/v1/point/get/${companyId}/${carId}/${month}/${year}`);
    }
    getAllPoints(companyId){
        return http.get(`/api/v1/point/all/${companyId}`);
    }
    deletePoint(pointId){
        return http.delete(`/api/v1/point/remove/${pointId}`)
    }
    fetchPoint(gpsLatitude, gpsLongitude) {
        return http.get('/api/v1/point/fetch', {
        params:{
            gpsLatitude,
            gpsLongitude
        }
        });
    }
    companyReturn(returnData){
        return http.post('/api/v1/point/companyReturn', returnData)
    }
    customPoint(customData){
        return http.post('/api/v1/point/addCustomPoint', customData)
    }

}

export default new PointService();