import http from './general';

const ReportService = {
    fetchRaports(fetchData){
        return http.post('/api/v1/raports/fetch', fetchData)
    },
    getRaports(raportId){
        return http.post(`/api/v1/raports/get/${raportId}`)
    },
    sendReports(generateForm){
        return http.post('/api/v1/raports/sendRaport ', generateForm)
    },
    getRaportsFromPoint(raportId){
        return http.get(`/api/v1/raports/points/${raportId}`)
    },
    getHistoryWithDate(companyId,date){
        return http.get(`/api/v1/raports/history/${companyId}/${date}`)
    },
    downloadRaport(raportId){
        return http.get(`/api/v1/raports/download/${raportId}`)
    }
}

export default ReportService