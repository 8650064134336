<template>
  <div class="t-py-6 t-w-8/12 t-h-screen t-mx-auto">
    <HeaderText class="t-hidden lg:t-flex"/>
    <div class="lg:t-hidden t-flex t-h-screen t-w-full t-items-center t-justify-center t-flex-col">
      <p class="t-text-2xl">Widok dostępny tylko na komputerach stacjonarnych i laptopach</p>
      <router-link to="/" class="t-mt-10">
        Wróć do logowania
      </router-link>
    </div>
    <PageHeader :pageTitle="pageTitle" :pageIcon="require('@/assets/file.png')" class="t-hidden lg:t-flex"/>
    <div class="t-flex t-mt-8 t-w-full t-hidden lg:t-flex">
      <div class="t-w-full t-h-full t-h-max-[550px] t-overflow-y-scroll t-border t-border-input-stroke t-rounded-lg">
        <table class="t-w-full t-mx-auto t-bg-input-bgc t-shadow-md t-rounded-lg">
          <thead class="t-h-12">
          <tr class="t-border-b t-border-input-stroke">
            <th class="t-w-[200px]">Pojazd</th>
            <th class="t-w-[200px]">Ostatni kierowca</th>
            <th class="t-w-[200px]">Data</th>
            <th class="t-w-[200px]">Miesiąc raportu</th>
            <th class="t-w-[100px]">Akcje</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(report, index) in reports" :key="index" :class="{ 'error-row': report.status === 'NOT_FOUND' }" class="t-h-16 t-border-b t-border-dashed t-border-input-stroke">
            <td>{{ report.vehicleInfo.vehicleName}} </td>
            <td>{{ report.lastDriverName }}</td>
            <td>{{ report.raportDate }}</td>
            <td>{{ report.raportMonth }}</td>
            <td class="t-flex t-items-center t-justify-center" >
              <span class="t-font-bold t-text-xs t-mt-5"  v-if="report.status === 'NOT_FOUND'">BRAK DANYCH</span>
              <div class="t-flex t-flex-col">
                <button
                    @click="previewReport(report.vehicleInfo.id)"
                    class="t-mt-4 t-flex t-flex-col t-items-center"
                    v-if="report.status === 'FOUND'"
                >
                  <img src="@/assets/pen.png" alt="Pen icon"/>
                  <span class="t-text-xs">Edytuj</span>
                </button>
                <button
                    @click="sendReport(index)"
                    class="t-mt-4 t-flex t-flex-col t-items-center"
                    v-if="report.status === 'FOUND'"
                >
                  <img src="@/assets/mail.png" alt="Mail icon"/>
                  <span class="t-text-xs">Wyślij na maila</span>
                </button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
  <div class="t-py-6 popup-overlay" v-if="isEditPopupVisible">
    <div class="t-flex t-flex-col popup-container t-px-4 t-py-2 t-bg-input-bgc">
      <h2>Edytuj Raport</h2>
      <form @submit.prevent="updatePoint" class="t-flex t-flex-col t-items-start">
        <label for="vehicleName" class="t-mt-2 label-style">Nazwa pojazdu</label>
        <input id="vehicleName" class="input-style-edit t-h-10" v-model="editedReport.vehicleName" type="text">

        <label for="regPlate" class="t-mt-2 label-style">Numer rejestracyjny</label>
        <input id="regPlate" class="input-style-edit t-h-10" v-model="editedReport.regPlate" type="text">

        <label for="lastMileage" class="t-mt-2 label-style">Ostatni przebieg (km)</label>
        <input id="lastMileage" class="input-style-edit t-h-10" v-model="editedReport.lastMileage" type="number">

        <label for="currentMileage" class="t-mt-2 label-style">Obecny przebieg (km)</label>
        <input id="currentMileage" class="input-style-edit t-h-10" v-model="editedReport.currentMileage" type="number">

        <label for="fuelConsumption" class="t-mt-2 label-style">Spalanie pojazdu (l/100km)</label>
        <input id="fuelConsumption" class="input-style-edit t-h-10" v-model="editedReport.fuelConsumption" type="number">

        <label for="lastDriverName" class="t-mt-2 label-style">Ostatni kierowca:</label>
        <input id="lastDriverName" class="input-style-edit t-h-10" v-model="editedReport.lastDriverName" type="text">

        <label for="reportDate" class="t-mt-2 label-style">Data raportu:</label>
        <input id="reportDate" class="input-style-edit t-h-10" v-model="editedReport.reportDate" type="text">

        <label for="reportMonth" class="t-mt-2 label-style">Miesiąc raportu:</label>
        <input id="reportMonth" class="input-style-edit t-h-10" v-model="editedReport.reportMonth" type="text">


        <div class="t-flex t-justify-between t-w-full t-mt-4 ">
          <button type="submit">Zapisz zmiany</button>
          <button @click="closeEditPopup">Anuluj</button>
        </div>
      </form>
    </div>
  </div>
  <div class="t-py-6 popup-overlay" v-if="isPreviewPopupVisible">
    <div class="t-flex t-flex-col t-px-4 t-py-2 t-max-w-[100%] t-min-w-[90%]  t-rounded-xl t-bg-input-bgc">
      <h2>Edytuj Raport</h2>
      <div class="t-flex t-flex-col t-my-10 t-px-2 t-h-full t-h-max-[550px] t-overflow-y-scroll t-border t-border-input-stroke t-rounded-lg">
        <table class="t-w-full t-bg-input-bgc">
          <thead>
          <tr>
            <th>Nazwa punktu</th>
            <th>Ulica</th>
            <th>Miasto</th>
            <th>Kod pocztowy</th>
            <th>Kraj</th>
            <th>Współrzędne GPS</th>
            <th>Data raportu</th>
            <th class="t-w-[50px]">Przebieg</th>
            <th class="t-w-[50px]">Dystans</th>
            <th>Akcje</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="point in pointDetails" :key="point.id" class="t-h-10">
            <td><input v-model="point.pointName" type="text" class="t-text-center"/></td>
            <td><input v-model="point.street" type="text" class="t-text-center"/></td>
            <td><input v-model="point.city" type="text" class="t-text-center"/></td>
            <td><input v-model="point.postalCode" type="text" class="t-text-center"/></td>
            <td><input v-model="point.country" type="text" class="t-text-center"/></td>
            <td><input v-model="point.gpsCoordinates" type="text" class="t-text-center"/></td>
            <td><input v-model="point.reportTime" type="text" class="t-text-center"/></td>
            <td><input v-model="point.currentMileage" type="number" class="t-text-center"/></td>
            <td><input v-model="point.distance" type="number" class="t-text-center"/></td>
            <td class="t-flex t-items-center t-justify-between t-h-10">
              <button @click="updatePoint(point)" class="t-font-bold t-mx-2" title="Edytuj punkt" >
                <img src="@/assets/edit.png" alt="Edit icon" class=""/>
              </button>
              <button @click="deletePoint(point.id)" class="t-font-bold">
                <img src="@/assets/delete.png" alt="Delete Icon" title="Usuń punkt"/>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="t-flex t-items-center t-justify-end t-my-2">
          <button @click="openAddPointPopup" class="t-flex t-items-center t-justify-center t-bg-bgc-btn-option t-px-2 t-py-1 t-rounded t-font-bold t-text-xs">+ Dodaj wiersz</button>
        </div>
        <div v-if="isAddPointPopupVisible" class="popup-overlay">
          <div class="t-flex t-flex-col popup-container t-px-4 t-py-2 t-bg-input-bgc">
            <h2 class="t-mb-4">Dodaj nowy punkt</h2>
            <form @submit.prevent="addPoint" class="t-flex t-flex-col">
              <select v-model="newPoint.pointType" required class="input-style t-mb-2 t-py-2">
                <option disabled value="">{{ 'Wybierz typ punktu' }}</option>
                <option v-for="(name, type) in pointTypeNames" :key="type" :value="type">
                  {{ name }}
                </option>
              </select>

              <input v-model="newPoint.street" placeholder="Ulica" required class="input-style t-mb-2 t-py-2"/>
              <input v-model="newPoint.city" placeholder="Miasto" required class="input-style t-mb-2 t-py-2"/>
              <input v-model="newPoint.postalCode" placeholder="Kod pocztowy" required class="input-style t-mb-2 t-py-2"/>
              <input v-model="newPoint.country" placeholder="Kraj" required class="input-style t-mb-2 t-py-2"/>
              <input v-model="newPoint.gpsLatitude" placeholder="Szerokość geograficzna" required class="input-style t-mb-2 t-py-2"/>
              <input v-model="newPoint.gpsLongitude" placeholder="Długość geograficzna" required class="input-style t-mb-2 t-py-2"/>
              <input v-model="newPoint.reportTime" placeholder="Data raportu" required class="input-style t-mb-2 t-py-2"/>
              <input v-model="newPoint.currentMileage" placeholder="Przebieg" type="number" required class="input-style t-mb-2 t-py-2"/>
              <input v-model="newPoint.distance" placeholder="Dystans" type="number" required class="input-style t-mb-2 t-py-2"/>

              <div class="t-flex t-items-center t-justify-center  t-mt-4">
                <button type="button" @click="closeAddPointPopup" class="t-mr-4 t-p-2">Anuluj</button>
                <button type="submit" class="t-p-2">Dodaj</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <button @click="closePreviewPopup">Zamknij</button>
    </div>
  </div>
  <PopupDialog :isVisible="isPopupVisible" :vehicleId="selectedVehicleId" :vehicleName="selectedVehicle" @close="closePopup" />
  <NotificationInfo
      :message="notificationMessage"
      v-if="showNotification"
      @close="closeNotification"
  />
  <ErrorPopup
      :isVisible="showError"
      :message="errorMessage"
      @close="() => showError = false"
  />
</template>
<script>
import HeaderText from "@/components/HeaderText";
import {computed, onMounted, ref} from "vue";
import PageHeader from "@/components/PageHeader";
import PopupDialog from "@/components/admin/PopupDialog";
import {useStore} from "vuex";
import TokenService from "@/plugins/token.service";
import NotificationInfo from "@/components/NotificationInfo.vue";
import ErrorPopup from "@/components/ErrorPopup.vue";
import {useToast} from "vue-toast-notification";
import 'vue-toast-notification/dist/theme-sugar.css';
export default {
  components: {PopupDialog, PageHeader, HeaderText,NotificationInfo,ErrorPopup},
  setup() {
    const store = useStore();
    const toast = useToast();
    const pageTitle = ref("Raporty pojazdów");
    const reports = computed(() => store.getters.reports);
    const isPopupVisible = ref(false);
    const isEditPopupVisible = ref(false);
    const isPreviewPopupVisible = ref(false);
    const selectedVehicle = ref("");
    const selectedVehicleId = ref(null);
    const editedReport = ref({});
    const pointDetails = ref([]);
    const showNotification = ref(false);
    const notificationMessage = ref("");
    const isAddPointPopupVisible = ref(false);
    const showError = ref(false);
    const errorMessage = ref('');

    const newPoint = ref({
      pointType: '',
      street: '',
      city: '',
      postalCode: '',
      country: '',
      gpsLatitude: '',
      gpsLongitude: '',
      reportTime: '',
      currentMileage: '',
      distance: ''
    });


    const getFirstAndLastDayOfMonth = () => {
      const date = new Date();
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      return {
        firstDay: formatDate(firstDay),
        lastDay: formatDate(lastDay)
      };
    };

    const formatDate = (date) => {
      const d = new Date(date),
          year = d.getFullYear(),
          month = `${d.getMonth() + 1}`.padStart(2, '0'),
          day = `${d.getDate()}`.padStart(2, '0');
      return [day, month, year].join('-');
    };

    onMounted(() => {
      const {firstDay, lastDay} = getFirstAndLastDayOfMonth();
      const fetchData = {
        dateFrom: firstDay,
        dateTo: lastDay,
        dateFormat: "dd-MM-yyyy",
        companyId: TokenService.getCompany()
      };
      store.dispatch('fetchReports', fetchData);
    });

    const newForm = ref(false);
    const pointTypes = ref(["PETROL_STATION", "CUSTOMER", "CARPARK", "HIGHWAY_GATE"]);
    const pointType = ref("");
    const pointTypeNames = {
      PETROL_STATION: "Stacja Paliw",
      CUSTOMER: "Klient",
      CARPARK: "Parking",
      HIGHWAY_GATE: "Brama Autostradowa"
    };

    const sendReport = (index) => {
      const report = reports.value[index];
      selectedVehicle.value = report.vehicleInfo.vehicleName;
      selectedVehicleId.value = report.vehicleInfo.id;
      isPopupVisible.value = true;
    };

    const editReport = (reportId) => {
      const reportToEdit = reports.value.find(report => report.vehicleInfo.id === reportId);
      if (reportToEdit) {
        editedReport.value = {
          ...reportToEdit.vehicleInfo,
          lastDriverName: reportToEdit.lastDriverName,
          reportDate: reportToEdit.raportDate,
          reportMonth: reportToEdit.raportMonth,
        };
        isEditPopupVisible.value = true;
      }
    };
    const previewReport = (vehicleId) => {
      const companyId = TokenService.getCompany();
      store.dispatch('getPointFromCar', { companyId, carId: vehicleId })
          .then(response => {
            if (response && response.data) {
              pointDetails.value = response.data;
            } else {
              pointDetails.value = store.getters.points;
            }
            selectedVehicleId.value = vehicleId;
            isPreviewPopupVisible.value = true;
          },error => {
            console.error("Error przy ladowaniu punktów", error);
            toast.error("Nie udało się załadować punktów" + error.response && error.response.data && error.response.data.message);
            pointDetails.value = [];
          });
    };

    const updatePoint = (point) => {
      const pointData = {
        id: point.id,
        pointName: point.pointName || '',
        pointType: point.pointType || '',
        street: point.street || '',
        city: point.city || '',
        postalCode: point.postalCode || '',
        country: point.country || '',
        gpsCoordinates: point.gpsCoordinates || '',
        vehicleId: point.vehicleId,
        reportTime: point.reportTime,
        currentMileage: point.currentMileage,
        distance: point.distance
      };
      console.log("dane z pointdata", pointData);
      store.dispatch('editPoint', pointData)
          .then(response => {
            if (response && response.status === 200) {
              toast.default("Udało się zaktualizować punkt",{
                duration:2000,
                icon: false
              });
            }
          },error => {
            console.error('Error przy aktualizacji punktu:', error);
            toast.error("Wystąpił błąd podczas próby aktualizacji punktu, " + error.response && error.response.data && error.response.data.message);
          });
    };

    const refuel = ref("");
    const gpsLatitude = ref("");
    const gpsLongitude = ref("");
    const mileage = ref("");
    const currentMileageRef = ref("");

    const addPoint = () => {
      const formatDate = (date) => {
        const d = new Date(date),
            year = d.getFullYear(),
            month = `${d.getMonth() + 1}`.padStart(2, '0'),
            day = `${d.getDate()}`.padStart(2, '0'),
            hours = `${d.getHours()}`.padStart(2, '0'),
            minutes = `${d.getMinutes()}`.padStart(2, '0'),
            seconds = `${d.getSeconds()}`.padStart(2, '0');

        return [day, month, year].join('-') + ' ' + [hours, minutes, seconds].join(':');
      };

      const reportTime = formatDate(new Date());

      const pointForm = {
        companyId: TokenService.getCompany(),
        carId: selectedVehicleId.value,
        reportTime: reportTime,
        reportTimeDateFormat: "dd-MM-yyyy HH:mm:ss",
        pointType: newPoint.value.pointType,
        pointName: pointTypeNames[newPoint.value.pointType] || newPoint.value.pointName,
        gpsLatitude: newPoint.value.gpsLatitude,
        gpsLongitude: newPoint.value.gpsLongitude,
        userId: TokenService.getUser().userId,
        currentMileage: parseFloat(newPoint.value.currentMileage),
      };
      store.dispatch("addPoint", pointForm)
          .then(() => {
            toast.default("Punkt został dodany pomyślnie",{
              duration:2000,
              icon: false
            });
            Object.keys(newPoint).forEach(key => {
              newPoint[key] = '';
            });
            previewReport(selectedVehicleId.value);
          },error => {
            toast.error("Błąd podczas dodawania nowego punktu: " + error.response && error.response.data && error.response.data.message);
          });
    };
    const deletePoint = (pointId) => {
      store.dispatch('deletePoint', pointId)
          .then(() => {
            console.log("Punkt został usunięty.");
            toast.default("Punkt został usunięty pomyślnie",{
              duration:2000,
              icon: false
            });
          },error => {
            console.error("Bład przy usuwaniu :", error);
            toast.error("Błąd podczas usuwania punktu: " + error.response && error.response.data && error.response.data.message);
          });
    };

    const closeEditPopup = () => {
      isEditPopupVisible.value = false;
    };
    const closePreviewPopup =()=>{
      isPreviewPopupVisible.value = false;
    };
    const closePopup = () => {
      isPopupVisible.value = false;
    };
    const closeNotification = () => {
      showNotification.value = false;
      notificationMessage.value = "";
    };
    function openAddPointPopup() {
      isAddPointPopupVisible.value = true;
    }

    function closeAddPointPopup() {
      isAddPointPopupVisible.value = false;
    }
    return {
      reports,
      pageTitle,
      isPopupVisible,
      selectedVehicle,
      isEditPopupVisible,
      editedReport,
      sendReport,
      closeEditPopup,
      closePopup,
      pointDetails,
      updatePoint,
      editReport,
      selectedVehicleId,
      isPreviewPopupVisible,
      closePreviewPopup,
      previewReport,
      showNotification,
      pointTypeNames,
      notificationMessage,
      closeNotification,
      newForm,
      pointTypes,
      pointType,
      isAddPointPopupVisible,
      newPoint,
      openAddPointPopup,
      showError,
      errorMessage,
      closeAddPointPopup,
      deletePoint,
      addPoint,
      refuel,
      mileage,
      gpsLongitude,
      gpsLatitude,
   currentMileageRef

    };
  },
}
</script>
<style scoped>

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.popup-container {
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  min-width: 400px;
  max-width: 400px;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
  text-align: center;
}
.error-row {
  background-color: #ffcccc;
}

</style>