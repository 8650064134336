<template>
  <div class="t-py-6 t-w-11/12 lg:t-w-8/12 t-h-full lg:t-h-screen t-mx-auto">
    <HeaderText class="t-hidden lg:t-flex"/>
    <PageHeader :pageTitle="pageTitle" :pageIcon="require('@/assets/map.png')" class="t-flex"/>
    <div class="t-flex t-mt-8 t-w-full">
      <div class="t-w-full t-h-full t-max-h-[620px] t-overflow-y-scroll t-border t-border-input-stroke t-rounded-lg t-hidden lg:t-flex">
        <table class="t-w-full t-mx-auto t-bg-input-bgc t-shadow-md t-rounded-lg">
          <thead class="t-h-12">
          <tr class="t-border-b t-border-input-stroke">
            <th class="t-w-[200px]">Nazwa</th>
            <th class="t-w-[200px]">Miejscowość</th>
            <th class="t-w-[200px]">Ulica i numer</th>
            <th class="t-w-[200px]">Kod pocztowy</th>
            <th class="t-w-[200px]">Typ punktu</th>
            <th class="t-w-[50px]">Akcje</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(address, index) in addresses" :key="index" class="t-h-16 t-border-b t-border-dashed t-border-input-stroke">
            <td>{{ address.pointName }}</td>
            <td>{{ address.city }}</td>
            <td>{{ address.street }}</td>
            <td>{{ address.postalCode }}</td>
            <td>{{ translatePointType(address.pointType) }}</td>
            <td class="t-flex t-items-center t-justify-center t-h-16">
              <button @click="deleteAddress(address.id)">
                <img src="@/assets/delete.png" alt="Delete icon"/>
              </button>
              <button @click="openEditDialog(address)" class="t-ml-2">
                <img src="@/assets/edit.png" alt="Edit icon" class="t-h-4 t-w-4"/>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="t-flex t-flex-col t-w-full t-h-full lg:t-hidden">
        <button class="confirm-btn t-py-2" @click="isAddingPopup = true"> Dodaj nowy adres </button>
        <div class="t-mt-6">
          <div
              class="t-flex t-flex-col t-items-start t-w-full t-p-3 t-mb-8 t-h-full t-rounded-lg t-shadow t-border t-border-input-stroke"
              v-for="(address, index) in addresses" :key="index"
          >
            <div class="t-flex t-justify-between t-items-center t-w-full">
              <p class="t-font-bold">{{ address.pointName }}</p>
              <p class="t-text-xs">#{{ address.id }}</p>
            </div>
            <p>{{ address.city }}</p>
            <p>{{ address.street }}</p>
            <p>{{ address.postalCode }}</p>
            <div class="t-flex t-justify-between t-items-center t-w-full">
              <p>{{ translatePointType(address.pointType) }}</p>
              <div class="t-flex">
                <button @click="deleteAddress(address.id)" class="t-px-2">
                  <img src="@/assets/delete.png" alt="Delete icon"/>
                </button>
                <button @click="openEditDialog(address)" class="t-ml-2 t-px-2">
                  <img src="@/assets/edit.png" alt="Edit icon" class="t-h-4 t-w-4"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Dodawanie adresu - desktop -->
      <div class="t-ml-8 t-p-4 t-w-[408px] t-h-[620px] t-bg-input-bgc t-border t-border-input-stroke t-rounded-lg t-shadow t-hidden lg:t-flex">
        <div class="t-flex t-justify-between t-items-start t-flex-col t-w-full t-h-full">
          <p class="t-font-bold">Dodaj adres</p>
          <form
              class="t-flex t-flex-col t-justify-between t-w-full t-mt-4 t-h-full"
              @submit.prevent="addAddress"
          >
            <div class="t-flex t-flex-col t-items-start t-w-full t-h-full">
              <label class="t-mb-1 label-style">Nazwa punktu*</label>
              <input
                  v-model="newAddress.pointName"
                  required
                  type="text"
                  class="input-style t-h-10"
              />
              <label class="t-mt-2 label-style">Kraj*</label>
              <input
                  v-model="newAddress.country"
                  required
                  type="text"
                  class="input-style t-h-10"
              />
              <label class="t-mt-2 label-style">Miejscowość*</label>
              <input
                  v-model="newAddress.city"
                  required
                  type="text"
                  class="input-style t-h-10"
              />
              <label class="t-mt-2 label-style">Ulica i numer*</label>
              <input
                  v-model="newAddress.street"
                  required
                  type="text"
                  class="input-style t-h-10"
              />
              <label class="t-mt-2 label-style">Numer mieszkania</label>
              <input
                  v-model="apartmentNumber"
                  type="text"
                  class="input-style t-h-10"
              />
              <label class="t-mt-2 label-style">Kod pocztowy*</label>
              <input
                  v-model="newAddress.postalCode"
                  required
                  type="text"
                  class="input-style t-h-10"
              />
              <label for="pointType" class="t-mt-2 label-style">Typ punktu*</label>
              <select v-model="newAddress.pointType" required class="input-style t-h-10">
                <option value="CUSTOMER">{{ translatePointType('CUSTOMER') }}</option>
                <option value="PETROL_STATION">{{ translatePointType('PETROL_STATION') }}</option>
                <option value="CARPARK">{{ translatePointType('CARPARK') }}</option>
                <option value="HIGHWAY_GATE">{{ translatePointType('HIGHWAY_GATE') }}</option>
              </select>

            </div>
            <div class="t-flex t-items-center t-justify-between t-w-full">
              <button class="flex-cen t-mt-4 t-h-14 t-w-full t-bg-btn-title t-text-main-bgc-font t-rounded-xl t-border t-border-input-stroke t-shadow t-text-lg">
                <span>Dodaj</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Edycja - popup -->
  <div v-if="isEditDialogOpen" class="overlay"></div>
  <div v-if="isEditDialogOpen" class="edit-dialog t-bg-input-bgc t-border t-border-input-stroke t-rounded-lg t-shadow" >
    <form @submit.prevent="updateAddress" class="t-flex t-flex-col t-items-start t-w-full t-h-full">
      <label class="t-mb-1 label-style">Nazwa punktu*</label>
      <input v-model="editableAddress.pointName" type="text" class="input-style t-mb-2 lg:t-mb-0 t-h-10 lg:t-h-14" required/>

      <label class="t-mb-1 label-style">Kraj*</label>
      <input v-model="editableAddress.country" type="text" class="input-style t-mb-2 lg:t-mb-0 t-h-10 lg:t-h-14" required/>

      <label class="t-mb-1 label-style">Miejscowość*</label>
      <input v-model="editableAddress.city" type="text" class="input-style t-mb-2 lg:t-mb-0 t-h-10 lg:t-h-14" required/>

      <label class="t-mb-1 label-style">Ulica i numer*</label>
      <input v-model="editableAddress.street" type="text" class="input-style t-mb-2 lg:t-mb-0 t-h-10 lg:t-h-14" required/>

      <label class="t-mb-1 label-style">Numer mieszkania</label>
      <input v-model="editableAddress.apartmentNumber" type="text" class="input-style t-mb-2 lg:t-mb-0 t-h-10 lg:t-h-14"/>

      <label class="t-mb-1 label-style">Kod pocztowy*</label>
      <input v-model="editableAddress.postalCode" type="text" class="input-style t-mb-2 lg:t-mb-0 t-h-10 lg:t-h-14" required/>

      <label for="pointType" class="t-mt-2 label-style">Typ punktu*</label>
      <select v-model="editableAddress.pointType" required class="input-style t-h-10">
        <option value="CUSTOMER">{{ translatePointType('CUSTOMER') }}</option>
        <option value="PETROL_STATION">{{ translatePointType('PETROL_STATION') }}</option>
        <option value="CARPARK">{{ translatePointType('CARPARK') }}</option>
        <option value="HIGHWAY_GATE">{{ translatePointType('HIGHWAY_GATE') }}</option>
      </select>

      <div class="t-flex t-items-center t-justify-between t-w-full t-mt-4 t-px-1">
        <button @click="isEditDialogOpen = false">Anuluj</button>
        <button type="submit" class="flex-cen t-py-4 t-pl-4 t-text-font-color t-font-bold t-text-sm">Zapisz zmiany</button>
      </div>
    </form>
  </div>
  <!-- Dodawanie mobilka popup -->
  <div v-if="isAddingPopup" class="overlay"></div>
  <div v-if="isAddingPopup" class="edit-dialog t-bg-input-bgc t-border t-border-input-stroke t-rounded-lg t-shadow" >
    <form @submit.prevent="addAddress" class="t-flex t-flex-col t-items-start t-w-full t-h-full">
      <label class="t-mb-1 label-style">Nazwa punktu*</label>
      <input v-model="newAddress.pointName" type="text" class="input-style t-mb-2 lg:t-mb-0 t-h-10 lg:t-h-14" required/>

      <label class="t-mb-1 label-style">Kraj*</label>
      <input v-model="newAddress.country" type="text" class="input-style t-mb-2 lg:t-mb-0 t-h-10 lg:t-h-14" required/>

      <label class="t-mb-1 label-style">Miejscowość*</label>
      <input v-model="newAddress.city" type="text" class="input-style t-mb-2 lg:t-mb-0 t-h-10 lg:t-h-14" required/>

      <label class="t-mb-1 label-style">Ulica i numer*</label>
      <input v-model="newAddress.street" type="text" class="input-style t-mb-2 lg:t-mb-0 t-h-10 lg:t-h-14" required/>

      <label class="t-mb-1 label-style">Numer mieszkania</label>
      <input v-model="newAddress.apartmentNumber" type="text" class="input-style t-mb-2 lg:t-mb-0 t-h-10 lg:t-h-14"/>

      <label class="t-mb-1 label-style">Kod pocztowy*</label>
      <input v-model="newAddress.postalCode" type="text" class="input-style t-mb-2 lg:t-mb-0 t-h-10 lg:t-h-14" required/>

      <label for="pointType" class="t-mt-2 label-style">Typ punktu*</label>
      <select v-model="newAddress.pointType" required class="input-style t-h-10">
        <option value="CUSTOMER">{{ translatePointType('CUSTOMER') }}</option>
        <option value="PETROL_STATION">{{ translatePointType('PETROL_STATION') }}</option>
        <option value="CARPARK">{{ translatePointType('CARPARK') }}</option>
        <option value="HIGHWAY_GATE">{{ translatePointType('HIGHWAY_GATE') }}</option>
      </select>
      <div class="t-flex t-items-center t-justify-between t-w-full t-mt-4 t-px-1">
        <button @click="isAddingPopup = false">Anuluj</button>
        <button type="submit" class="flex-cen t-py-4 t-pl-4 t-text-font-color t-font-bold t-text-sm">Dodaj</button>
      </div>
    </form>
  </div>
  <NotificationInfo
      v-if="showAddNotification"
      :message="addNotificationMessage"
      @close="showAddNotification = false"
  />
  <NotificationInfo
      v-if="showDeleteNotification"
      :message="deleteNotificationMessage"
      @close="showDeleteNotification = false"
  />
</template>
<script>
import {ref, onMounted, computed} from "vue";
import { useStore } from "vuex";
import HeaderText from "@/components/HeaderText";
import NotificationInfo from "@/components/NotificationInfo";
import PageHeader from "@/components/PageHeader";
import TokenService from "@/plugins/token.service";
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
export default {
  components: {PageHeader, NotificationInfo, HeaderText},
  setup() {
    const store = useStore();
    const toast = useToast();
    const pageTitle = ref("Ustawienia adresów");

    const showAddNotification = ref(false);
    const addNotificationMessage = ref("");

    const showDeleteNotification = ref(false);
    const deleteNotificationMessage = ref("");

    const isEditDialogOpen = ref(false);
    const isAddingPopup = ref(false);
    const editableAddress = ref({});

    const openEditDialog = (address) => {
      editableAddress.value = { ...address };
      isEditDialogOpen.value = true;
    };

    const updateAddress = async () => {
      const addressData = {
        ...editableAddress.value
      };
      await store.dispatch('editAddress', { addressId: editableAddress.value.id, addressData })
          .then(() => {
            isEditDialogOpen.value = false;
            toast.default("Adres został zaktualizowany",{
              duration:2000,
              icon: false
            });
          },error => {
            toast.error("Błąd podczas aktualizacji adresu: " + error.response && error.response.data && error.response.data.message);
          });
    };


    function translatePointType(key) {
      const translations = {
        CUSTOMER: 'Klient',
        PETROL_STATION: 'Stacja benzynowa',
        CARPARK: 'Parking',
        HIGHWAY_GATE: 'Bramka autostradowa',
      };
      return translations[key] || key;
    }

    const addresses = computed(() => store.getters.addresses);

    onMounted(() => {
      store.dispatch('getAddresses');
    });

    const apartmentNumber = ref('')

    const newAddress = ref({
      pointName:"",
      country:"",
      city: "",
      street: "",
      postalCode: "",
      pointType: "",
    });
    const addAddress = async () => {
      const addressData = {
        pointName: newAddress.value.pointName,
        country: newAddress.value.country,
        city: newAddress.value.city,
        street: newAddress.value.street,
        postalCode: newAddress.value.postalCode,
        pointType: newAddress.value.pointType,
        companyId: TokenService.getCompany()
      };
      await store.dispatch('addAddress', addressData)
          .then(() => {
            toast.default("Nowy adres został dodany.",{
              duration:2000,
              icon: false
            });
            newAddress.value = {
              pointName: '',
              city: '',
              country: '',
              street: '',
              postalCode: '',
              companyId: TokenService.getCompany(),
              pointType: '',
            };
          }, (error) => {
            if (error.response && error.response.data && error.response.data.message) {
              console.log("error przy dodawaniu adresu")
            }
            toast.error("Błąd podczas dodawania adresu: " + error.response && error.response.data && error.response.data.message);
          });
    };

    const deleteAddress = async (addressId) => {
      await store.dispatch('removeAddress', addressId).then(
          () => {
            store.dispatch('getAddresses');
            toast.default("Adres został usunięty.",{
              duration:2000,
              icon: false
            });
          },
          (error) => {
            if (error.response && error.response.data && error.response.data.message) {
              console.error("Szczegóły błędu:", error.response.data.message);
            }
            toast.error("Błąd podczas usuwania adresu: " + error.message);

          }
      );
    }


    return {
      updateAddress,
      pageTitle,
      addresses,
      newAddress,
      addAddress,
      deleteAddress,
      showAddNotification,
      addNotificationMessage,
      showDeleteNotification,
      isEditDialogOpen,
      editableAddress,
      openEditDialog,
      deleteNotificationMessage,
      translatePointType,
      isAddingPopup,
      apartmentNumber
    };
  },
}
</script>

<style scoped>
.edit-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  padding: 20px;
  width: 90%;
  max-width: 350px;
}

.edit-dialog form {
  display: flex;
  flex-direction: column;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
</style>