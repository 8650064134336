v<template>
  <div class="t-py-6 t-w-11/12 lg:t-w-8/12 t-h-full lg:t-h-screen t-mx-auto">
    <HeaderText class="t-hidden lg:t-flex"/>
    <PageHeader :pageTitle="pageTitle" :pageIcon="require('@/assets/map.png')" class="t-flex"/>
    <div class="t-flex t-mt-8 t-w-full">
      <div class="t-w-full t-h-full t-max-h-[620px] t-overflow-y-scroll t-hidden lg:t-flex">
        <div class="t-w-full t-flex t-justify-between">
          <div class="t-flex t-flex-col t-items-center t-justify-start">
            <p>Form 1 - dodawanie trasy</p>
            <form @submit.prevent="addPoint" class="t-flex t-flex-start t-flex-col t-mt-4">
              <label class="t-text-start t-mb-1 label-style">pojazd</label>
              <select v-model="selectedVehicleId" @change="updateSelectedVehicle" class="input-style t-h-12">
                <option value="">Wybierz pojazd</option>
                <option v-for="vehicle in vehicles" :key="vehicle.id" :value="vehicle.id">
                  {{ vehicle.vehicleName }}, {{ vehicle.regPlate }}
                </option>
              </select>
              <label class="t-text-start t-mb-1 label-style">Typ punktu</label>
              <select v-model="pointType" class="input-style t-h-12" required>
                <option value="">Wybierz typ punktu</option>
                <option v-for="type in pointTypes" :key="type" :value="type">{{ type }}</option>
              </select>
              <label class="t-text-start t-mb-1 label-style">latitude</label>
              <input
                  v-model="gpsLatitude"
                  class="input-style t-h-12"
              />
              <label class="t-text-start t-mb-1 label-style">longitude</label>
              <input
                  v-model="gpsLongitude"
                  class="input-style t-h-12"
              />
              <label class="t-text-start t-mb-1 label-style">przebieg (aktualny - {{ currentMileageRef }} km)</label>
              <input
                  v-model="mileage"
                  class="input-style t-h-12"
              />
              <button type="submit">Dodaj</button>
            </form>
          </div>
          <div class="t-flex t-flex-col t-items-center t-justify-start">
            <p>Form 2 - dodawanie pracownikow</p>
            <form class="t-flex t-flex-start t-flex-col t-mt-4" @submit.prevent="inviteNewUser" >
              <label class="t-text-start t-mb-1 label-style">Imię</label>
              <input
                  type="text"
                  class="input-style t-h-14"
                  v-model="firstName"
                  required
              />
              <label class="t-text-start t-mt-4 label-style">Nazwisko</label>
              <input
                  type="text"
                  class="input-style t-h-14"
                  v-model="lastName"
                  required
              />
              <label class="t-text-start t-mt-4 label-style">E-mail</label>
              <input
                  type="email"
                  class="input-style t-h-14"
                  v-model="email"
                  required
              />
              <button class="flex-cen t-mt-4" type="submit">Dodaj</button>
            </form>
          </div>
          <div class="t-flex t-flex-col t-items-center t-justify-center">
            <p>Form 3 - dodawanie adresow</p>
            <form @submit.prevent="addAddress" class="t-flex t-flex-start t-flex-col t-mt-4">
              <label class="t-mb-1 label-style">Nazwa punktu*</label>
              <input
                  v-model="newAddress.pointName"
                  required
                  type="text"
                  class="input-style t-h-10"
              />
              <label class="t-mt-2 label-style">Kraj*</label>
              <input
                  v-model="newAddress.country"
                  required
                  type="text"
                  class="input-style t-h-10"
              />
              <label class="t-mt-2 label-style">Miejscowość*</label>
              <input
                  v-model="newAddress.city"
                  required
                  type="text"
                  class="input-style t-h-10"
              />
              <label class="t-mt-2 label-style">Ulica i numer*</label>
              <input
                  v-model="newAddress.street"
                  required
                  type="text"
                  class="input-style t-h-10"
              />
              <label class="t-mt-2 label-style">Kod pocztowy*</label>
              <input
                  v-model="newAddress.postalCode"
                  required
                  type="text"
                  class="input-style t-h-10"
              />
              <label for="pointType" class="t-mt-2 label-style">Typ punktu*</label>
              <select v-model="newAddress.pointType" required class="input-style t-h-10">
                <option value="CUSTOMER">{{ translatePointType('CUSTOMER') }}</option>
                <option value="PETROL_STATION">{{ translatePointType('PETROL_STATION') }}</option>
                <option value="CARPARK">{{ translatePointType('CARPARK') }}</option>
                <option value="HIGHWAY_GATE">{{ translatePointType('HIGHWAY_GATE') }}</option>
              </select>
              <button class="flex-cen t-mt-4">
                <span>Dodaj</span>
              </button>

            </form>
          </div>
        </div>

      </div>
    </div>
  </div>


</template>
<script>
import {ref, computed, onMounted} from "vue";
import { useStore } from "vuex";
import HeaderText from "@/components/HeaderText";
import PageHeader from "@/components/PageHeader";
import TokenService from "@/plugins/token.service";
export default {
  components: {PageHeader, HeaderText},
  setup() {
    const store = useStore();
    const pageTitle = ref("DEVELOPERS TEST");

    //cars
    const selectedVehicleId = ref("");

    // Pobieranie pojazdów przy montowaniu komponentu
    onMounted(() => {
      store.dispatch("getVehicles");
    });

    // Reaktywne pobieranie listy pojazdów
    const vehicles = computed(() => store.getters.vehicles);

    // Aktualizacja wybranego pojazdu
    const updateSelectedVehicle = () => {
      store.commit("SET_SELECTED_VEHICLE_ID", selectedVehicleId.value);
    };

    //dodwanie adresu
    const pointTypes = ref(["PETROL_STATION", "CUSTOMER", "CARPARK", "HIGHWAY_GATE"]);
    const pointType = ref("");
    const latitude = ref(null);
    const longitude = ref(null);
    const city = ref("");
    const street = ref("");
    const postCode = ref("");
    const country = ref("");
    const refuel = ref("");
    const gpsLatitude = ref("");
    const gpsLongitude = ref("");
    const mileage = ref("");
    const gasStationName = ref("");
    const currentMileageRef = ref("");
    const pointTypeNames = {
      PETROL_STATION: "Stacja Paliw",
      CUSTOMER: "Klient",
      CARPARK: "Parking",
      HIGHWAY_GATE: "Brama Autostradowa"
    };
    const addPoint = () => {
      if (!("geolocation" in navigator)) {
        return;
      }
      navigator.geolocation.getCurrentPosition(async () => {

        const formatDate = (date) => {
          const d = new Date(date),
              year = d.getFullYear(),
              month = `${d.getMonth() + 1}`.padStart(2, '0'),
              day = `${d.getDate()}`.padStart(2, '0'),
              hours = `${d.getHours()}`.padStart(2, '0'),
              minutes = `${d.getMinutes()}`.padStart(2, '0'),
              seconds = `${d.getSeconds()}`.padStart(2, '0');

          return [day, month, year].join('-') + ' ' + [hours, minutes, seconds].join(':');
        };

        const reportTime = formatDate(new Date());

        const pointForm = {
          companyId: TokenService.getCompany(),
          carId: Number(TokenService.getSelectedVehicleId()),
          reportTime: reportTime,
          reportTimeDateFormat: "dd-MM-yyyy HH:mm:ss",
          pointType: pointType.value,
          pointName: pointTypeNames[pointType.value],
          refuel: Number(refuel.value),
          gpsLatitude : gpsLatitude.value,
          gpsLongitude  : gpsLongitude.value,
          userId: TokenService.getUser().userId,
          currentMileage: parseFloat(mileage.value),
        };
        store.dispatch("addPoint", pointForm)
            .then(async () => {
              currentMileageRef.value = mileage.value
              console.log("Punkt został dodany.");
              gasStationName.value = '';
              gpsLongitude.value = '';
              gpsLatitude.value = '';
              gpsLongitude.value = '';
              mileage.value = '';
            }, error => {
              console.error("Bład podczas dodawania punktu:", error);
            });
      }, error => {
        console.error("Błąd geolokalizacji:", error);
      },);
    }
    //dodawnie pracownikow
    const errorMessage = ref('');
    const firstName = ref('');
    const lastName = ref('');
    const email = ref('');
    const inviteNewUser = async () => {
      const inviteForm = {
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        companyId: TokenService.getCompany()
      };
      await store.dispatch('inviteUser', inviteForm)
          .then(async (response) => {
            console.log('Zapraszanie użytkownika:', response);
            await store.dispatch('getCompanyAllUsers');
            console.log("dodano pracownika z danymi:", inviteForm)
          }, error => {
            if (error.response) {
              if (error.response.status === 500) {
                errorMessage.value = "Nie udało się dodać pracownika. Skontaktuj się z działem pomocy.";
              } else if (error.response.data && error.response.data.msg) {
                errorMessage.value = error.response.data.msg;
              } else {
                errorMessage.value = 'Nie udało się dodać pracownika. Spróbuj ponownie później.';
              }
            } else {
              errorMessage.value = 'Wystąpił błąd podczas dodawania pracownika. Spróbuj ponownie później.';
            }
          });
    };
    //dodawanie pracownikow
    function translatePointType(key) {
      const translations = {
        CUSTOMER: 'Klient',
        PETROL_STATION: 'Stacja benzynowa',
        CARPARK: 'Parking',
        HIGHWAY_GATE: 'Bramka autostradowa',
      };
      return translations[key] || key;
    }
    const newAddress = ref({
      pointName:"",
      country:"",
      city: "",
      street: "",
      postalCode: "",
      pointType: "",
    });
    const addAddress = async () => {
      const addressData = {
        pointName: newAddress.value.pointName,
        country: newAddress.value.country,
        city: newAddress.value.city,
        street: newAddress.value.street,
        postalCode: newAddress.value.postalCode,
        pointType: newAddress.value.pointType,
        companyId: TokenService.getCompany()
      };
      await store.dispatch('addAddress', addressData)
          .then(() => {
            console.log("dodano")
          }, (error) => {
            if (error.response && error.response.data && error.response.data.message) {
              console.log("error przy dodawaniu adresu")
            }
          });
    };

    return {
      pageTitle,
      selectedVehicleId,
      updateSelectedVehicle,
      latitude,
      errorMessage,
      longitude,
      addPoint,
      vehicles,
      country,
      postCode,
      city,
      street,
      mileage,
      currentMileageRef,
      pointTypes,
      pointType,
      pointTypeNames,
      inviteNewUser,
      email,
      lastName,
      firstName,
      translatePointType,
      newAddress,
      addAddress,
      gpsLatitude,
      gasStationName,
      gpsLongitude
    };
  },
}
</script>

<style scoped>
.edit-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  padding: 20px;
  width: 90%;
  max-width: 350px;
}

.edit-dialog form {
  display: flex;
  flex-direction: column;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
</style>