import VehicleService from '@/plugins/vehicle.service'
import TokenService from "@/plugins/token.service";

 const state = {
        vehicles: [],
        selectedVehicleId: null,
    };
 const mutations= {
        SET_VEHICLES(state, vehicles) {
            console.log("Mutacja SET_VEHICLE::", state.vehicles);
            state.vehicles = vehicles;
        },
     ADD_VEHICLE(state, vehicle) {
         console.log("Mutacja ADD_VEHICLE: dodawanie pojazdu::", vehicle);
         state.vehicles.push(vehicle);
     },
     SET_SELECTED_VEHICLE_ID(state, vehicleId) {
         state.selectedVehicleId = vehicleId;
         TokenService.setSelectedVehicleId(vehicleId);
     },
 };

const actions = {
    getVehicles({ commit }) {
        const companyId = TokenService.getCompany();
        return VehicleService.vehicleGetAll(companyId).then(
            (response) => {
                console.log("Dane pojazdów przed commit:", response.data);
                commit('SET_VEHICLES', response.data);
                return response.data;
            }, (error) => {
                console.error("Blad przy pobieraniu pojazdów:", error);
            }
        );
    },
    addVehicle({ state,commit }, vehicleData) {
            return VehicleService.vehicleAdd(vehicleData).then(response => {
                commit('SET_VEHICLES', [...state.vehicles, response.data]);
                return response.data;
            }, error => {
                console.error("Blad przy dodawaniu caru:", error);
                return error;
            });
        },
        editVehicle({ dispatch }, vehicleData) {
            return VehicleService.vehicleEdit(vehicleData).then(response => {
                dispatch('getVehicles', vehicleData.companyId);
                return response.data;
            }, error => {
                console.error("Blad przy edycji caru:", error);
                return error;
            });
        },
        deleteVehicle({ dispatch }, { vehicleId, companyId }) {
            return VehicleService.vehicleDelete(vehicleId).then(response => {
                dispatch('getVehicles', companyId);
                return response;
            }, error => {
                console.error("Blad przy usuwaniu caru:", error);
                return error;
            });

        },
};
const getters = {
    vehicles: state => state.vehicles,
    selectedVehicle: state => {
        return state.vehicles.find(vehicle => vehicle.id === state.selectedVehicleId);
    },
};
export default {
        state,
        mutations,
        actions,
    getters
};
