<template>
  <SpinnerLoading v-if="spinner"/>
  <LoadingScreen/>
  <div class="flex-cen t-flex-col t-h-screen t-w-screen t-pb-40 lg:t-pb-20">
    <div class="flex-cen t-flex-col">
      <h1 class="t-text-logo-font t-text-3xl">RoadReporter</h1>
      <p class="t-mt-3 t-text-sm t-text-info-font">Łatwiejsze raporty</p>
    </div>
    <div class="flex-cen t-flex-col lg:t-w-[400px] t-w-full t-px-4">
      <p class="t-my-10 t-text-xl t-text-info-font">Przypomnij hasło</p>
      <form class="t-flex t-items-start t-flex-col t-w-full" @submit.prevent="remindPassword">
        <label class="t-mb-1 label-style">E-mail</label>
        <input
            v-model="email"
            class="input-style t-h-14"
            type="text"
            required
        />
        <button
            class="t-mt-10 t-h-14 confirm-btn"
            type="submit"
        >
          Przypomnij
        </button>
      </form>

      <div
          class="t-flex t-items-center t-justify-center t-flex-col t-mt-10 t-w-[400px] t-px-4 t-hidden lg:t-flex">
        <p class="t-text-xl t-text-info-font">Zarejestruj swoją firmę</p>
        <router-link
            class="flex-cen t-mt-4 t-h-14 t-w-full t-bg-btn-info t-text-main-bgc-font t-text-xl t-rounded-xl t-border t-border-input-stroke t-shadow"
            to="/register"
        >
          <span>Zarejestruj się</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import SpinnerLoading from "@/components/SpinnerLoading";
import LoadingScreen from "@/components/LoadingScreen";
import { ref } from "vue";
import { useStore } from 'vuex';

export default {
  name: 'HomeView',
  components: {
    LoadingScreen,
    SpinnerLoading
  },
  setup() {
    const spinner = ref(false);
    const email = ref('');
    const store = useStore();

    const remindPassword = () => {
      if (email.value) {
        spinner.value = true; // Pokaż spinner podczas wysyłania żądania
        store.dispatch('remindPass', { email: email.value })
            .then(() => {
              alert('Link do zresetowania hasła został wysłany na podany adres e-mail.');
            })
            .catch(error => {
              alert('Wystąpił błąd: ' + error.message);
            })
            .finally(() => {
              spinner.value = false;
            });
      }
    };

    return {
      spinner,
      email,
      remindPassword
    }
  }
}
</script>
