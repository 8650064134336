<template>
  <transition name="slide-down">
    <div v-if="isVisible" class="error-popup" @click="close">
      {{ translatedMessage }}
    </div>
  </transition>
</template>

<script>
import { ref, watch, computed } from 'vue';
import errorTranslations from '@/utils/errorTranslations.js';

export default {
  name: 'ErrorPopup',
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    message: String,
  },
  emits: ['close'],
  setup(props, { emit }) {
    const visible = ref(props.isVisible);

    const translatedMessage = computed(() => {
      return errorTranslations[props.message] || props.message || 'Nieznany błąd';
    });

    watch(() => props.isVisible, (newValue) => {
      visible.value = newValue;
      if (newValue) {
        setTimeout(() => {
          emit('close');
          visible.value = false;
        }, 2000);
      }
    });

    return { visible, translatedMessage };
  }
};
</script>

<style>
.slide-down-enter-active, .slide-down-leave-active {
  transition: transform 0.5s ease-out, opacity 0.5s ease;
}
.slide-down-enter, .slide-down-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}
.error-popup {
  position: fixed;
  top: 20px;
  right: 50%;
  transform: translateX(50%);
  background-color: #181818;
  color: white;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
}
</style>
