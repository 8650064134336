<template>
  <div class="t-hidden lg:t-flex t-h-screen t-w-full t-items-center t-justify-center t-flex-col">
    <p class="t-text-2xl">Widok dostępny tylko na urządzeniach mobilnych</p>
    <router-link to="/" class="t-mt-10">
      Wróć do logowania
    </router-link>
  </div>
  <div class="t-py-10 lg:t-hidden t-overflow-hidden">
    <h1 class="t-text-logo-font t-text-2xl t-font-bold">Klient</h1>
    <p class="t-mt-14 t-text-xl">Podaj wszystkie informację</p>
    <form class="t-flex t-flex-col t-px-4 t-w-full t-mt-8 t-text-start" @submit.prevent="addClient">
      <label class="label-style">Pojazd</label>
      <input readonly class="input-style t-h-14" v-model="selectedVehicleName" />
      <label class="t-mt-4 label-style">Przebieg pojazdu (aktualny - {{ currentMileageRef }} km)</label>
      <input
          v-model="mileage"
          type="number"
          class="input-style t-h-14"
          placeholder="km"
          required
      />
      <label class="t-mt-4 label-style">Nazwa klienta*</label>
      <input
          v-model="clientName"
          type="text"
          class="input-style t-h-14"
          placeholder="Pan Zbyszek"
          required
      />

      <button
          type="submit"
          class="t-mt-10 t-h-14 confirm-btn"
      >
        Dodaj klienta
      </button>
    </form>
  </div>
</template>

<script>
import {onMounted, ref} from "vue";
import TokenService from "@/plugins/token.service";
import {useStore} from 'vuex'
export default {
  setup(){
    const store = useStore();
    const city = ref("");
    const streetAndNumber = ref("");
    const zipCode = ref("");
    const clientName = ref("");
    const country = ref("");
    const mileage = ref("");

    const currentMileageRef = ref("");
    const selectedVehicleName = ref("");

    onMounted(async () => {
      await refreshVehicle()
    });
    const fetchGPS = ()=> {
      if (!("geolocation" in navigator)) {
        return;
      }
      navigator.geolocation.getCurrentPosition(async (position) => {
        const gpsLatitude = position.coords.latitude.toString();
        const gpsLongitude = position.coords.longitude.toString();
        await store.dispatch('fetchPoint',{gpsLatitude,gpsLongitude}).then(res =>{
          console.log("fetch gps",res)
          city.value = res.data.city || 'Brak danych';
          country.value = res.data.country || 'Brak danych';
          zipCode.value = res.data.postCode;
          streetAndNumber.value = res.data.street;
        }), err =>{
          console.log("bład w pobieraniu adresuy", err);
        }
      })
    }
    const refreshVehicle = async () => {
      await store.dispatch("getVehicles").then(res => {
        const all = res;
        const savedVehicleId = TokenService.getSelectedVehicleId();
        const vehicle = all.find(vehicle => vehicle.id.toString() === savedVehicleId);
        if (vehicle) {
          console.log(vehicle)
          selectedVehicleName.value = `${vehicle.vehicleName}, ${vehicle.regPlate}`;
          currentMileageRef.value = vehicle.currentMileage;
          console.log(currentMileageRef.value)
        }
      })
    }
    const addClient = () => {
      if (!("geolocation" in navigator)) {
        return;
      }
      navigator.geolocation.getCurrentPosition(async (position) => {
        const gpsLatitude = position.coords.latitude.toString();
        const gpsLongitude = position.coords.longitude.toString();

        const formatDate = (date) => {
          const d = new Date(date),
              year = d.getFullYear(),
              month = `${d.getMonth() + 1}`.padStart(2, '0'),
              day = `${d.getDate()}`.padStart(2, '0'),
              hours = `${d.getHours()}`.padStart(2, '0'),
              minutes = `${d.getMinutes()}`.padStart(2, '0'),
              seconds = `${d.getSeconds()}`.padStart(2, '0');

          return [day, month, year].join('-') + ' ' + [hours, minutes, seconds].join(':');
        };

        const reportTime = formatDate(new Date());

        const pointForm = {
          companyId: TokenService.getCompany(),
          carId: Number(TokenService.getSelectedVehicleId()),
          reportTime: reportTime,
          reportTimeDateFormat: "dd-MM-yyyy HH:mm:ss",
          pointType: "CUSTOMER",
          pointName: clientName.value,
          gpsLatitude,
          gpsLongitude,
          refuel: null,
          userId: TokenService.getUser().userId,
          currentMileage: parseFloat(mileage.value),
        };
        store.dispatch("addPoint", pointForm)
            .then(async () => {
              currentMileageRef.value =  mileage.value
              console.log("Punkt został pomyślnie dodany.");
              clientName.value = '';
              mileage.value = '';
            },error => {
              console.error("Błąd podczas dodawania punktu:", error);
            });
      }, error => {
        console.error("Błąd geolokalizacji:", error);
      },);
    };
    return {
      zipCode,
      streetAndNumber,
      city,
      clientName,
      country,
      mileage,
      currentMileageRef,
      fetchGPS,
      selectedVehicleName,
      addClient,

    };
  }
}
</script>

<style scoped>

</style>

