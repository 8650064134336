<!-- LoadingScreen.vue -->
<template>
  <transition name="fade">
  <div v-if="isVisible" class="loading-screen t-flex t-flex-col">
    <h1 class="t-text-logo-font t-text-[30px]">RoadReporter</h1>
    <p class="t-mt-3 t-text-sm t-text-info-font">Łatwiejsze raporty</p>
  </div>
  </transition>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  setup() {
    const isVisible = ref(true);

    onMounted(() => {
      setTimeout(() => {
        isVisible.value = false;
      }, 500);
    });

    return {
      isVisible,
    };
  },
};
</script>

<style scoped>
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color:#E4E4E4;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
