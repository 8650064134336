<template>
  <div class="t-relative t-flex t-items-end t-justify-end t-w-full t-mx-auto t-h-12 t-z-30">
    <h1 class="t-absolute t-top-50 t-left-0 t-right-0 t-text-logo-font t-text-3xl t-z-30">RoadReporter</h1>
    <button @click="logout" class="t-flex t-items-center t-justify-center t-w-10 t-h-10 t-bg-input-stroke t-rounded-lg t-shadow-md t-z-30">
      <img src="@/assets/sign-out.png" alt="Exit Icon" class="t-w-6 t-h-6"/>
    </button>
  </div>
</template>

<script>
import TokenService from "@/plugins/token.service";
import {useRouter} from "vue-router";

export default {
setup(){
  const router = useRouter();
  const logout = () => {
    console.log("LOGOUT")
    TokenService.removeUser();
    router.push({name: 'home'});
  }

  return {
    logout
  }
}
}
</script>

<style scoped>

</style>
