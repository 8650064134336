import axios from "axios";

const http = axios.create({
    baseURL: 'https://kilometrowka-zayn-60e340123200.herokuapp.com',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://kilometrowka-zayn-60e340123200.herokuapp.com'
    },
});

export default http;
