import http from './general';
import TokenService from "@/plugins/token.service";

class AuthService {
  registerCompany(registerForm) {
    return http.post('/api/v1/auth/registerCompany', {
      firstName: registerForm.firstName,
      lastName: registerForm.lastName,
      email: registerForm.email,
      password: registerForm.password
    });
  }

  async refreshToken(token) {
    return http.post(`/api/v1/auth/refreshToken/${token}`);
  }

  loginUser(loginForm) {
    return http
    .post('/api/v1/auth/login', {
      email: loginForm.email,
      password: loginForm.password
    })

  }

  inviteUser(inviteForm) {
    return http.post('/api/v1/auth/inviteUser', {
      firstName: inviteForm.firstName,
      lastName: inviteForm.lastName,
      email: inviteForm.email,
      companyId: TokenService.getCompany()
    })
  }

  confirmRegistration(t) {
    return http.post('/api/v1/auth/confirmRegistration', {
      password: t.password,
      registrationToken: t.token,
    })
  }

  deleteUser(userId) {
    return http.delete(`/api/v1/auth/deleteUser/${userId}`)
  }

  remindPass(p) {
    return http.post('/api/v1/auth/remindPassword', {
      email: p.email,
    });
  }

  resetPass(p) {
    return http.post('/api/v1/auth/resetPassword', {
      token: p.token,
      newPassword: p.newPassword,
    });
  }

}

export default new AuthService();
