<template>
  <SpinnerLoading v-if="spinner"/>
  <LoadingScreen/>
  <div class="flex-cen t-flex-col t-h-screen t-w-screen t-pb-40 lg:t-pb-20">
    <div class="flex-cen t-flex-col">
      <h1 class="t-text-logo-font t-text-3xl">RoadReporter</h1>
      <p class="t-mt-3 t-text-sm t-text-info-font">Łatwiejsze raporty</p>
    </div>
    <div class="flex-cen t-flex-col lg:t-w-[400px] t-w-full t-px-4">
      <p class="t-my-10 t-text-xl t-text-info-font">Podaj hasło</p>
      <form class="t-flex t-items-start t-flex-col t-w-full" @submit.prevent="confirm">
        <label class="t-mb-1 label-style">Hasło*</label>
        <input
            v-model="password"
            class="input-style t-h-14"
            type="password"
            required
        />
        <p v-if="passwordError" class="error-message">{{ passwordError }}</p>
        <label class="t-mt-4 label-style">Potwierdź hasło*</label>
        <input
            v-model="confirmPassword"
            class="input-style t-h-14"
            type="password"
            required
        />
        <p v-if="confirmPasswordError" class="error-message">{{ confirmPasswordError }}</p>

        <button
            class="t-mt-10 t-h-14 confirm-btn"
            type="submit"
        >
          Potwierdź
        </button>
      </form>
      <div
          class="t-flex t-items-center t-justify-center t-flex-col t-mt-10 t-w-[400px] t-px-4 t-hidden lg:t-flex">
        <p class="t-text-xl t-text-info-font">Zarejestruj swoją firmę</p>
        <router-link
            class="flex-cen t-mt-4 t-h-14 t-w-full t-bg-btn-info t-text-main-bgc-font t-text-xl t-rounded-xl t-border t-border-input-stroke t-shadow"
            to="/register"
        >
          <span>Zarejestruj się</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import SpinnerLoading from "@/components/SpinnerLoading";
import LoadingScreen from "@/components/LoadingScreen";
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

export default {
  name: 'HomeView',
  components: {LoadingScreen, SpinnerLoading},
  setup() {
    const spinner = ref(false);
    const password = ref('');
    const confirmPassword = ref('');
    const passwordError = ref('');
    const confirmPasswordError = ref('');

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const token = route.params.token || '';

    const confirm = () => {
      spinner.value = true;
      passwordError.value = '';
      confirmPasswordError.value = '';

      if (password.value.length < 8) {
        passwordError.value = 'Hasło musi zawierać co najmniej 8 znaków.';
        spinner.value = false;
        return;
      }
      if (!/\d/.test(password.value)) {
        passwordError.value = 'Hasło musi zawierać co najmniej jedną cyfrę.';
        spinner.value = false;
        return;
      }
      if (!/[!@#$%^&*(),.?":{}|<>]/.test(password.value)) {
        passwordError.value = 'Hasło musi zawierać co najmniej jeden znak specjalny.';
        spinner.value = false;
        return;
      }
      if (password.value !== confirmPassword.value) {
        confirmPasswordError.value = 'Hasła nie są identyczne.';
        spinner.value = false;
        return;
      }

      store.dispatch('confirmRegistration', { token, password: password.value })
          .then(() => {
            router.push({ name: 'home' });
            spinner.value = false;
          }),error => {
        passwordError.value = 'Błąd podczas potwierdzania hasła: ' + (error.response?.data?.message || 'Nieznany błąd');
        spinner.value = false;
      };
    };

    return {
      spinner,
      confirm,
      password,
      confirmPassword,
      passwordError,
      confirmPasswordError,
    };

  }
}
</script>
<style>
.error-message {
  color: red;
  margin-top: 7px;
  margin-left:4px;
  font-size:10px;
}
</style>
