
<template>
  <div class="t-flex t-items-center t-justify-center lg:t-justify-between t-w-full lg:t-w-7/12 t-py-10 lg:t-py-0 lg:t-mt-20">
    <router-link to="/admin-panel" class="t-flex t-items-center t-pr-20 t-hidden lg:t-flex">
      <img src="@/assets/back.png" alt="Back button" />
      <span class="t-ml-2 t-text-xs">Wróć</span>
    </router-link>
    <div class="t-flex t-items-center">
      <img :src="pageIcon" alt="Page Icon" />
      <span class="t-ml-2 t-text-xl t-font-bold t-text-logo-font">{{ pageTitle }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageTitle: String,
    pageIcon: String,
  },
};
</script>

<style scoped>
</style>
