<template>
  <div class="t-py-6 t-w-8/12 t-h-screen t-mx-auto">
    <HeaderText class="t-hidden lg:t-flex"/>
    <div class="t-flex t-items-center t-justify-between t-w-7/12 t-mt-20">
      <router-link to="/test" class="t-flex t-items-center t-pr-20">
        <img src="@/assets/back.png" alt="Back button" />
        <span class="t-ml-2 t-text-xs">Wróć</span>
      </router-link>
      <div class="t-flex t-items-center">
        <img src="@/assets/map.png" alt="Page Icon" />
        <span class="t-ml-2 t-text-xl t-font-bold t-text-logo-font">Fake trasa</span>
      </div>
    </div>
    <div class="t-flex t-items-start">
      <div class="t-flex t-flex-col t-items-start">
        <form class="t-flex t-flex-col t-px-4 t-w-full t-mt-8 t-text-start" @submit.prevent>
          <label class="label-style">Firma</label>
          <select
              v-model="selectedCompany"
              class="input-style t-h-14"
              required
          >
            <option value="">Wybierz firmę</option>
            <option>ZaynCode</option>
            <option>PIK</option>
            <option>SuperMocni</option>
          </select>
          <label class="t-mt-4 label-style">Pracownik</label>
          <input
              v-model="employee"
              type="text"
              class="input-style t-h-14"
              placeholder="Pracownik"
              required
          />
          <label class="t-mt-4 label-style">Pojazd</label>
          <select
              v-model="selectedVehicle"
              class="input-style t-h-14"
              required
          >
            <option value="">Wybierz pojazd</option>
            <option>Skoda Fabia - CB 11111</option>
            <option>Alfa Romeo - CT 2222</option>
            <option>Samochód Tomka</option>
            <option>Formuła</option>
          </select>
          <label class="t-mt-4 label-style">Przebieg pojazdu (aktualny - 200km)</label>
          <input
              v-model="mileage"
              type="text"
              class="input-style t-h-14"
              placeholder="km"
              required
          />
          <label class="t-mt-4 label-style">Ilość zatankowanego paliwa (l) </label>
          <input
              v-model="fuelAmount"
              type="text"
              class="input-style t-h-14"
              placeholder="l"
              required
          />
          <label class="t-mt-4 label-style">Średnie spalanie (l/100km)</label>
          <input
              v-model="averageConsumption"
              type="text"
              class="input-style t-h-14"
              placeholder="l/100km"
              required
          />
          <button
              type="submit"
              class="t-mt-10 t-h-14 confirm-btn"
          >
            Generuj trasę
          </button>
        </form>
      </div>
      <div class="t-mt-14 t-h-full t-h-max-[550px] t-overflow-y-scroll t-border t-border-input-stroke t-rounded-lg">
        <table class="t-w-full t-mx-auto t-bg-input-bgc t-shadow-md t-rounded-lg">
          <thead class="t-h-12">
          <tr class="t-border-b t-border-input-stroke">
            <th class="t-w-[50px]">Numer kolejny wpisu</th>
            <th class="t-w-[100px]">Data wyjazdu</th>
            <th class="t-w-[200px]">Opis trasy wyjazdu</th>
            <th class="t-w-[200px]">Cel wyjazdu</th>
            <th class="t-w-[100px]">Liczba faktycznie przejechanych km</th>
            <th class="t-w-[100px]">Imię i nazwisko</th>
            <th class="t-w-[100px]">Licznik start</th>
            <th class="t-w-[100px]">Licznik stop</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(road, index) in roads" :key="index" class="t-h-16 t-border-b t-border-dashed t-border-input-stroke">
            <td>{{ road.id }}</td>
            <td>{{ road.roadDate }}</td>
            <td>{{ road.roadDescription }}</td>
            <td>{{ road.roadGoal }}</td>
            <td>{{ road.roadKM }}</td>
            <td>{{ road.name }}</td>
            <td>{{ road.kmStart }}</td>
            <td>{{ road.kmStop }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderText from "@/components/HeaderText";
import {ref} from "vue";
export default {
  components: {HeaderText },
  setup(){
    const roads = ref([
      {id:'1', roadDate:'05.02.2024',roadDescription:'Bydgoszcz, Jana Biziela 20/43', roadGoal:'Spotkanie z klientem',roadKM:'50', name:'Krystian Głogowski', kmStart:'110531',kmStop:'11081' },


    ]);

    const employee = ref('');
    const selectedCompany = ref('');
    const selectedVehicle = ref("");
    const mileage = ref("");
    const fuelAmount = ref("");
    const averageConsumption = ref("");
    return {
      selectedVehicle,
      mileage,
      fuelAmount,
      averageConsumption,
      employee,
      selectedCompany,
      roads
    }
  }
}
</script>

<style scoped>

</style>