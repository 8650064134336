class TokenService{
    getLocalAccessToken() {
        const user = JSON.parse(localStorage.getItem("user"));
        return user?.token;
    }
    getUser() {
        return JSON.parse(localStorage.getItem("user"));
    }

    setUser(user) {
        localStorage.setItem("user", JSON.stringify(user));
    }
    getUserId(){
        if(this.getUser()){
            return this.getUser().id;
        }
        return null;
    }

    getFullName(){
        if(this.hasUser()){
            return this.getUser().firstName+" "+this.getUser().lastName;
        }
        return "";
    }

    hasUser(){
        return this.getUser()!=null;
    }
    removeUser() {
        localStorage.removeItem("user");
    }
    getCompany(){
        if(this.getUser()!=null){
            return this.getUser().companyId;
        }
        return [-1];
    }

isFirstLogin(){
    console.log('logowanie 3')
    const user = JSON.parse(localStorage.getItem("user"));
console.log("usernull?",(user!==null),user.firstLogin,(user!=null&& user.firstLogin))
    return user!=null&& user.firstLogin;
}

setFirstLogin(){
    let user = JSON.parse(localStorage.getItem("user"));
    user.firstLogin = false;
    localStorage.setItem("user", JSON.stringify(user));
}

    setSelectedVehicleId(vehicleId) {
        localStorage.setItem("selectedVehicleId", vehicleId);
    }

    getSelectedVehicleId() {
        return localStorage.getItem("selectedVehicleId") || null;
    }

    getLocalRefreshToken() {
        const user = JSON.parse(localStorage.getItem("user"));
        return user?.refreshToken;
    }

    updateLocalAccessToken(token) {
        let user = JSON.parse(localStorage.getItem("user"));
        user.token = token;
        localStorage.setItem("user", JSON.stringify(user));
    }
    updateLocalRefreshToken(token) {
        let user = JSON.parse(localStorage.getItem("user"));
        user.refreshToken = token;
        localStorage.setItem("user", JSON.stringify(user));
    }
}

export default new TokenService();
