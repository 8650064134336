<template>
  <SpinnerLoading v-if="spinner"/>
  <LoadingScreen/>
  <div class="flex-cen t-flex-col t-h-screen t-w-screen t-pb-40 lg:t-pb-20">
    <div class="flex-cen t-flex-col">
      <h1 class="t-text-logo-font t-text-3xl">RoadReporter</h1>
      <p class="t-mt-3 t-text-sm t-text-info-font">Łatwiejsze raporty</p>
    </div>
    <div class="flex-cen t-flex-col lg:t-w-[400px] t-w-full t-px-4">
      <p class="t-my-10 t-text-xl t-text-info-font">Zaloguj się</p>
      <form class="t-flex t-items-start t-flex-col t-w-full" @submit.prevent="login">
        <label class="t-mb-1 label-style">E-mail</label>
        <input
            v-model="email"
            class="input-style t-h-14"
            type="text"
        />
        <label class="t-mt-4 t-ml-1 label-style">Hasło</label>
        <input
            v-model="password"
            class="input-style t-h-14"
            type="password"
        />
        <button
            class="t-mt-10 t-h-14 confirm-btn"
            type="submit"
        >
          Zaloguj
        </button>
      </form>
      <router-link to="/remind-password" class="t-mt-4 t-border-b t-border-input-stroke t-w-full t-pb-2">Przypomnij hasło</router-link>
      <div
          class="t-flex t-items-center t-justify-center t-flex-col t-mt-6 t-w-[400px] t-px-4 t-hidden lg:t-flex">
        <p class="t-text-xl t-text-info-font">Zarejestruj swoją firmę</p>
        <router-link
            class="flex-cen t-mt-4 t-h-14 t-w-full t-bg-btn-info t-text-main-bgc-font t-text-xl t-rounded-xl t-border t-border-input-stroke t-shadow"
            to="/register"
        >
          <span>Zarejestruj się</span>
        </router-link>
      </div>
    </div>
    <ErrorPopup :isVisible="showError" :message="errorMessage" @close="showError = false" />
  </div>
</template>

<script>

import SpinnerLoading from "@/components/SpinnerLoading";
import ErrorPopup from "@/components/ErrorPopup";
import {onMounted, ref} from "vue";
import {useRouter} from 'vue-router';
import LoadingScreen from "@/components/LoadingScreen";
import {useStore} from 'vuex'
import TokenService from "@/plugins/token.service";

export default {
  name: 'HomeView',
  components: {LoadingScreen,ErrorPopup, SpinnerLoading},
  setup() {
    const router = useRouter();
    const store = useStore();
    const spinner = ref(false);
    const password = ref('');
    const email = ref('');
    const showError = ref(false);
    const errorMessage = ref('');

    onMounted(() => {
      if (TokenService.getUser()!=null && store.getters.isAuthenticated) {
        const mobile = window.matchMedia('(max-width: 768px)').matches;
        if (!mobile) {
          router.push({name: 'AdminDashboard'});
        } else {
          router.push({name: 'DriverDashboard'});
        }
      }
    });
    const login = async () => {
      console.log('start');
      spinner.value = true;
      const loginForm = {
        email: email.value,
        password: password.value
      }
      await store.dispatch('loginUser', loginForm).then(res => {
        spinner.value = false;
        console.log("odpowiedź:", res);
        if (res.status === 200) {
          console.log("sukces");
          router.push({name: 'AdminDashboard'});
        }
      },error => {
        showError.value = true;
        if (error.response && error.response.data && error.response.data.errorCode) {
          switch(error.response.data.msg) {
            case "User not exist!":
              errorMessage.value = "Nie istnieje taki użytkownik.";
              break;
            case "Password is incorrect":
              errorMessage.value = "Podane hasło jest nieprawidłowe.";
              break;
            default:
              errorMessage.value = error.response.data.msg;
          }
        } else {
          errorMessage.value = 'Wystąpił błąd podczas logowania. Spróbuj ponownie później.';
        }
      }).finally(() => {
        spinner.value = false;
      });
    };
    return {
      spinner,
      password,
      email,
      errorMessage,
      showError,
      login
    }
  }
}
</script>
