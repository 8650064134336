<template>
  <div class="popup-overlay " v-if="isVisible" @click="closePopup">
    <div class="t-flex t-flex-col popup-container t-px-4 t-py-2 t-bg-input-bgc" @click.stop>
      <h2 class="t-font-bold">Uzupełnij informację</h2>
      <div class="t-mt-8">
        <p>Raport dla pojazdu:</p>
        <p class="t-font-bold">{{ vehicleName }}</p>
      </div>
      <div class="t-flex t-flex-col t-items-start t-mt-8">
        <label for="mileage" class="t-mb-1  label-style">Aktualny przebieg pojazdu:</label>
        <input v-model="mileage" type="number" id="mileage"  class="input-style-edit t-h-10" />
      </div>
      <div class="t-w-full t-flex t-items-center t-justify-between t-h-14 t-text-sm">
        <button @click="sendReport">Wyślij na maila</button>
        <button @click="closePopup">Anuluj</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import TokenService from "@/plugins/token.service";

export default {
  props: {
    isVisible: Boolean,
    vehicleName: String,
    vehicleId: Number,
  },
  emits: ["close"],
  setup(props, { emit }) {
    const store = useStore();
    const mileage = ref("");

    const closePopup = () => emit("close");

    const sendReport = () => {
      const generateForm = {
        companyId: TokenService.getCompany(),
        vehicleId: props.vehicleId,
        currentMileage: mileage.value,
        currentDate: new Date().toLocaleString('pl-PL', { timeZone: 'Europe/Warsaw' }).replace('T', ' ').replaceAll('.','-').replace(',',''),
        dateFormat: "dd-MM-yyyy HH:mm:ss",
      };
      console.log(generateForm)

      store.dispatch("sendReports", generateForm)
          .then(() => {
            console.log("Raport został wygenerowany i wysłany.");
            closePopup();
          })
          .catch(error => {
            console.error("Błąd generowania raportu:", error);
          });
    };

    return {
      mileage,
      closePopup,
      sendReport,
    };
  },
};
</script>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-container {
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
  text-align: center;
}
</style>
