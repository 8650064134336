const errorTranslations = {
    WRONG_PASSWORD: "Złe hasło użytkownika",
    VEHICLE_NOT_FOUND: "Pojazd nie odnaleziony",
    USER_NOT_FOUND: "Użytkownik nie odnaleziony",
    USER_EXIST: "Użytkownik już istnieje",
    UNKNOWN_ERROR: "NIEZNANY BŁĄD",
    TOKEN_REFRESH_FAILED: "Błąd odświeżenia tokenu",
    RAPORT_NOT_FOUND: "Raport nie odnaleziony",
    POINT_NOT_FOUND: "Punkt nie odnaleziony",
    INVALID_POINT_TYPE: "Niepoprawny typ punktu",
    INVALID_VEHICLE_MILEAGE: "Niepoprawny przebieg auta",
    INVALID_LOCATION: "Niepoprawna lokalizacja (błąd wyciągania z GPS)",
    COMPANY_NOT_FOUND: "FIRMA NIE ODNALEZIONA",
    ADDRESS_NOT_FOUND: "Adres nie odnaleziony"
};

export default errorTranslations;